import { createBrowserRouter, Outlet } from "react-router-dom";
import Home from "../view/home";
import Footer from "../layout/footer";
import Header from "../layout/header";
import BrandAndAlliance from "../view/BrandAndAlliance";
import Solutions from "../view/Solutions";
import ContactUs from "../view/contactUs";
import Products from "../view/products";
import AboutUsPage from "../view/aboutUsPage";


const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <>
        <Header />
        <Outlet />
        <Footer />
      </>
    ),
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/brand-alliance",
        element: <BrandAndAlliance />,
      },
      {
        path: "/solutions",
        element: <Solutions />,
      },
      {
        path: "/products",
        element: <Products />,
      },
      {
        path: "/contact-us",
        element: <ContactUs />,
      },{
        path: "/about-us",
        element: <AboutUsPage />,
      },
    ],
  },
]);

export default router;
