import { Box, Grid } from "@mui/material";
import React from "react";
import Heading from "../../../../components/Headings";

const Partners = () => {
  const partners = [
    { img: "https://res.cloudinary.com/dm9ak3vvw/image/upload/v1730098350/image_25_yq8x1a.svg", link: "https://www.dlink.com/in/en" },
    { img: "https://res.cloudinary.com/dm9ak3vvw/image/upload/v1730098439/image_26_skfz4m.svg", link: "http://sophos.com/" },
    { img: "https://res.cloudinary.com/dm9ak3vvw/image/upload/v1730098428/image_27_oyj6ln.svg", link: "http://tp-link.com/" },
    { img: "https://res.cloudinary.com/dm9ak3vvw/image/upload/v1730098431/image_28_ypkyjh.svg", link: "https://www.commscope.com/" },
    { img: "https://res.cloudinary.com/dm9ak3vvw/image/upload/v1730098431/image_29_hvgszc.svg", link: "http://hikvision.com/" },
    { img: "https://res.cloudinary.com/dm9ak3vvw/image/upload/v1730098430/image_30_cmrbwy.svg", link: "https://www.netrackindia.com/" },
    { img: "https://res.cloudinary.com/dm9ak3vvw/image/upload/v1730098431/image_31_m7buro.svg", link: "http://cisco.com/" },
    { img: "https://res.cloudinary.com/dm9ak3vvw/image/upload/v1730098433/image_32_gizto0.svg", link: "https://www.cpplusworld.com/" },
    { img: "https://res.cloudinary.com/dm9ak3vvw/image/upload/v1730098432/image_33_ncetkm.svg", link: "https://www.digisol.com/" },
    { img: "https://res.cloudinary.com/dm9ak3vvw/image/upload/v1730098434/image_34_qwcqob.svg", link: "http://ui.com/" },
    { img: "https://res.cloudinary.com/dm9ak3vvw/image/upload/v1730098435/image_35_yghy67.svg", link: "http://fluke.com/" },
    { img: "https://res.cloudinary.com/dm9ak3vvw/image/upload/v1730098437/image_36_fsb3db.svg", link: "https://www.seagate.com/in/en/" },
    {img: "https://www.numericups.com/themes/numeric/logo.svg", link: "https://www.numericups.com/" },
  ];

  return (
    <Grid
      container
      mt={6}
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Grid item>
        <Heading
          head={"Partnership"}
          text={
            "Experience reliability, performance, and innovation with the trusted brands available at DATA SYSTEMS. Your satisfaction is our priority."
          }
        />
      </Grid>
      <Grid
        container
        spacing={1.5}
        justifyContent="center"
        sx={{ mt: 4, width: { xs: "95%", sm: "100%" } }}
      >
        {partners.map((partner, index) => (
          <Grid item key={index} xs={4} sm={4} md={2}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: { xs: "60px", sm: "100px" },
                cursor:"pointer"
              }}
              onClick={() =>
                window.open(partner.link, "_blank", "noopener,noreferrer")
              }
            >
              <img
                src={partner.img}
                alt={`partner-${index + 1}`}
                style={{ maxWidth: "100%", maxHeight: "100%" }}
              />
            </Box>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default Partners;
