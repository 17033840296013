import { Grid, Typography } from "@mui/material";
import React from "react";

const Heading = ({ head, text }) => {
  return (
    <Grid
      container
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography
        sx={{ fontSize: { xs: "35px", sm: "45px" }, fontWeight: 700 }}
      >
        {head}
      </Typography>
      <Typography
        sx={{
          fontSize: {xs:"12px",sm:"16px"},
          textAlign: "center",
          width: {xs:"90%",sm:"60%"},
          fontWeight: 400,
        }}
      >
        {text}
      </Typography>
    </Grid>
  );
};

export default Heading;
