import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";

const Ceo = () => {
  return (
    <Grid
      sx={{
        padding: { md: "30px 40px" },
        height: { xs: "55vh", md: "60vh" },
        backgroundRepeat: "no-repeat",
      }}
    >
      <Container
        // sx={{
        //   width: "100%",
        //   height: "100%",
        //   display: "flex",
        //   backgroundImage:"https://res.cloudinary.com/dm9ak3vvw/image/upload/v1728848744/ceoSectionBanner_gm7tj2.png",
        //   borderRadius: { md: "25px" },
        //   padding: "0px",
        // }}
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          backgroundImage: {
            xs: "linear-gradient(to right,#c4e080, #FCFEE2, #E1FF8A,#cee7cb )",
            md: "url('https://res.cloudinary.com/dm9ak3vvw/image/upload/v1728848744/ceoSectionBanner_gm7tj2.png')",
          },
          backgroundSize: "contain", // Optional: Ensures the image covers the container
          backgroundPosition: "center", // Optional: Centers the image
          backgroundRepeat: "no-repeat",
          borderRadius: { md: "25px" },
          padding: "0px",
        }}
      >
        {/* <img src={ceo} alt="ceo" style={{ width: "65%", height: "100%" }} /> */}
        <Grid
          item
          sx={{
            width: "50%",
            display: { xs: "none", md: "flex" },
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {/* <Typography
            sx={{
              alignSelf: { xs: "center" },
              fontSize: { xs: "30px", md: "35px" },
            }}
          >
            Rakesh Mishra
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: "70px", md: "100px" },
              fontWeight: 600,
              color: "#fff",
            }}
          >
            CEO
          </Typography> */}
        </Grid>
        <Grid
          item
          sx={{
            width: { xs: "100%", md: "50%" },
            padding: { md: "0px 20px" },
            marginTop: { md: "3rem" },
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: { xs: "75%", md: "60%" },
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              padding: { md: "20px" },
            }}
          >
            <Typography
              sx={{
                fontSize: { xs: "14px", md: "17px" },
                width: "90%",
                mt: { md: 1 },
                alignSelf: { xs: "center" },
              }}
            >
              With over 40 years of experience in the industry, Rakesh Mishra
              has been instrumental in steering Data Systems towards new heights
              of excellence. His vision and leadership have been pivotal in
              establishing Data Systems as a trusted name in networking
              solutions.
            </Typography>
          </Box>
          <Box
            sx={{
              width: "100%",
              padding: "0px 10px",
              mt: { xs: "-9px", md: "-30px" },
            }}
          >
            <Typography
              sx={{
                display: { xs: "block", md: "none" },
                fontSize: { xs: "70px", md: "100px" },
                fontWeight: 600,
                color: "#fff",
              }}
            >
              CEO
            </Typography>
          </Box>
        </Grid>
      </Container>
    </Grid>
  );
};

export default Ceo;
