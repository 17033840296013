import { Box, Container, Grid, Typography, useMediaQuery } from "@mui/material";
import React from "react";

const Vision = () => {
  const isSmall = useMediaQuery("(max-width:600px)");
  return (
    <Grid sx={{ height: { md: "70vh" }, mt: 5 }}>
      <Container
        sx={{
          display: { xs: "initial", md: "flex" },
          justifyContent: "space-evenly",
          width: "100%",
          height: "100%",
          padding: { xs: "0px", md: "10px" },
        }}
      >
        <Grid item sx={{ width: { xs: "100%", md: "45%" }, height: "100%" }}>
          <img
            src={"https://res.cloudinary.com/dm9ak3vvw/image/upload/v1730107325/vision_ev58nc.svg"}
            alt="vision"
            style={{ width: "100%", height: "100%" }}
          />
        </Grid>
        <Grid
          item
          sx={{
            width: { xs: "100%", md: "50%" },
            display: { xs: "flex", md: "initial" },
            justifyContent: "center",
            mt: { xs: 5, md: 0 },
          }}
        >
          {!isSmall ? (
            <>
              <Box
                sx={{
                  display: { xs: "none", md: "flex" },
                  width: "100%",
                  height: "68%",
                }}
              >
                <Box
                  sx={{
                    m: 1,
                    width: "47%",
                    backgroundColor: "#FCFEE2",
                    borderRadius: "20px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "0px 10px",
                    border: "1px solid #edefd5",
                  }}
                >
                  <Typography sx={{ fontSize: "13px", fontWeight: 400 }}>
                    At Data Systems, we envision a future where every
                    organization is empowered by seamless, intelligent IT
                    networking solutions that drive unparalleled connectivity
                    and innovation. As the CEO, I am committed to steering our
                    company toward this future by leveraging cutting-edge
                    technology, fostering a culture of continuous improvement,
                    and prioritizing our clients' unique needs.
                  </Typography>
                </Box>
                <Box
                  sx={{
                    m: 1,
                    width: "47%",
                    backgroundColor: "#FCFEE2",
                    borderRadius: "20px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "0px 10px",
                    border: "1px solid #edefd5",
                  }}
                >
                  <Typography sx={{ fontSize: "13px", fontWeight: 400 }}>
                    Our vision is to be the global leader in IT networking,
                    recognized not only for our technological expertise but also
                    for our unwavering dedication to delivering transformative
                    solutions that shape the future of digital interaction. We
                    strive to create a world where our clients operate at peak
                    efficiency, harnessing the full potential of their data
                    through networks that are not only robust and secure but
                    also adaptive and forward-thinking.
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  m: 1,
                  height: "30%",
                  width: "95%",
                  backgroundColor: "#FCFEE2",
                  borderRadius: "20px",
                  display: { xs: "none", md: "flex" },
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "10px",
                  border: "1px solid #edefd5",
                }}
              >
                <Typography sx={{ fontSize: "13px", fontWeight: 400 }}>
                  By investing in the brightest minds and embracing the latest
                  advancements in technology, we aim to set new benchmarks in
                  the industry, ensuring that Data Systems remains synonymous
                  with excellence and innovation. Together, we will build a
                  connected world where possibilities are limitless, and every
                  challenge is met with groundbreaking solutions.
                </Typography>
              </Box>
            </>
          ) : (
            <Box
              sx={{
                width: "90%",
                backgroundColor: "#FCFEE2",
                borderRadius: "20px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                padding: "10px",
                border: "1px solid #edefd5",
              }}
            >
              <Typography sx={{ fontSize: "13px", fontWeight: 400 }}>
                At Data Systems, we envision a future where every organization
                is empowered by seamless, intelligent IT networking solutions
                that drive unparalleled connectivity and innovation. As the CEO,
                I am committed to steering our company toward this future by
                leveraging cutting-edge technology, fostering a culture of
                continuous improvement, and prioritizing our clients' unique
                needs.
              </Typography>{" "}
              <Typography sx={{ fontSize: "13px", fontWeight: 400 }}>
                Our vision is to be the global leader in IT networking,
                recognized not only for our technological expertise but also for
                our unwavering dedication to delivering transformative solutions
                that shape the future of digital interaction. We strive to
                create a world where our clients operate at peak efficiency,
                harnessing the full potential of their data through networks
                that are not only robust and secure but also adaptive and
                forward-thinking.
              </Typography>
              <Typography sx={{ fontSize: "13px", fontWeight: 400 }}>
                By investing in the brightest minds and embracing the latest
                advancements in technology, we aim to set new benchmarks in the
                industry, ensuring that Data Systems remains synonymous with
                excellence and innovation. Together, we will build a connected
                world where possibilities are limitless, and every challenge is
                met with groundbreaking solutions.
              </Typography>
            </Box>
          )}
        </Grid>
      </Container>
    </Grid>
  );
};

export default Vision;
