import { Grid } from "@mui/material";
import React from "react";
import Heading from "../../../../components/Headings";
import ImageBox from "./components/ImageBox";

const AboutUs = () => {
  return (
    <Grid container mt={6}>
      <Grid
        item
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Heading
          head={"About Us"}
          text={
            "Data Systems is an organization for providing Networking and Security solutions since 1984. Our business is through partners, system integrators, dealers and resellers."
          }
        />
        <ImageBox />
      </Grid>
    </Grid>
  );
};

export default AboutUs;
