import React from "react";
import Box from "@mui/material/Box";
import HomeBanner from "./components/HomeBanner";
import AboutUs from "./components/AboutUs";
import { Container, Grid } from "@mui/material";
import Partners from "./components/Partners";
import BestSellers from "./components/BestSellers";
import DataSystem from "./components/DataSystem";
import Achievements from "./components/Achievements";

const Home = () => {
  return (
    <Grid sx={{width:"100%",padding:{xs:"0px 20px",md:"0px 50px"} }}>
      <Container disableGutters sx={{ maxWidth: "100% !important" }}>
        <HomeBanner />
        <AboutUs />
        <BestSellers />
        <Partners />
        <DataSystem />
        <Achievements />
      </Container>
    </Grid>
  );
};
export default Home;
