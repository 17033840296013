import { Grid, Typography } from "@mui/material";
import React from "react";

const HeadingLight = ({ head, text }) => {
  return (
    <Grid
      container
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        mt: { lg: 6 },
      }}
    >
      <Typography
        sx={{
          fontSize: { xs: "25px", lg: "40px" },
          fontWeight: 400,
          textAlign: { xs: "left", md: "center" },
          lineHeight: { xs: "25px" },
        }}
      >
        {head}
      </Typography>
      <Typography
        sx={{
          fontSize: { xs: "15px", md: "16px" },
          textAlign: { xs: "left", md: "center" },
          width: { xs: "100%", md: "95%" },
          fontWeight: 400,
          mt: 2,
        }}
      >
        {text}
      </Typography>
    </Grid>
  );
};

export default HeadingLight;
