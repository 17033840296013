import { Grid } from '@mui/material';
import React from 'react'
import ProductSubHead from '../../../../components/ProductSubHead';
import ProdImgBox from '../../../../components/ProductsImgBox';

const Firewall = () => {
    const content = [
        {
          img: "https://res.cloudinary.com/dm9ak3vvw/image/upload/v1730102227/Firewall5_slzlst.svg",
          title: "Sophos Firewalls ",
        },
        {
          img: "https://res.cloudinary.com/dm9ak3vvw/image/upload/v1730102219/Firewall1_erldwi.svg",
          title: "Sophos Zero Trust Network Access",
        },
        {
          img: "https://res.cloudinary.com/dm9ak3vvw/image/upload/v1730102224/Firewall2_lc6iyw.svg",
          title: "Sophos End Points",
        },
        {
          img: "https://res.cloudinary.com/dm9ak3vvw/image/upload/v1730102220/Firewall3_k4sx3i.svg",
          title: "Sophos MDR",
        },
        // {
        //   img: Firewall4,
        //   title: "QNAP",
        // },
      ];
    return (
        <Grid container mt={"5px"}>
          <Grid item xs={12}>
            <ProductSubHead
              title={"Firewall & AV Solutions"}
              description={
                "Protect your network with our comprehensive suite of firewall and antivirus solutions, designed to safeguard your business from evolving threats. Our offerings include:"
              }
            />
          </Grid>
          <Grid item sx={{ width: "100%", mt: 5 }}>
            <ProdImgBox content={content} />
          </Grid>
        </Grid>
      );
}

export default Firewall