import { Box, Grid, Typography } from "@mui/material";
import React, { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

const ResponsiveBox = ({ imageBox }) => {
  const swiperRef = useRef(null);
  return (
    <Box>
      <Swiper
        ref={swiperRef}
        className="custom-swiper"
        spaceBetween={10}
        slidesPerView={3}
        navigation={{
          nextEl: ".swiper-next",
          prevEl: ".swiper-prev",
        }}
        breakpoints={{
          1440: { slidesPerView: 3, spaceBetween: 50 },
          1220: { slidesPerView: 3, spaceBetween: 50 },
          1100: { slidesPerView: 3, spaceBetween: 50 },
          1024: { slidesPerView: 3, spaceBetween: 0 },
          1000: { slidesPerView: 3, spaceBetween: 0 },
          900: { slidesPerView: 3, spaceBetween: 0 },
          800: { slidesPerView: 2.7, spaceBetween: 10 },
          650: { slidesPerView: 2, spaceBetween: 10 },
          500: { slidesPerView: 1.7, spaceBetween: 10 },
          0: { slidesPerView: 1.3, spaceBetween: 20, dragSize: 100 },
        }}
        style={{ width: "80vw" }}
      >
        {imageBox.map((item, index) => (
          <SwiperSlide
            key={index}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Grid
              container
              key={index}
              sx={{
                width: "225px",
                height: "400px",
                position: "relative",
              }}
            >
              <Grid item sx={{ width: "100%", height: "60%" }}>
                <img
                  src={item?.img}
                  alt={item?.title}
                  style={{ width: "100%", height: "100%" }}
                />
              </Grid>
              <Grid
                item
                sx={{
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  background:
                    "linear-gradient(to bottom, rgba(255, 255, 255, 0.05),rgba(255, 255, 255, 1) ,rgba(255, 255, 255, 1))",
                }}
              >
                <Typography
                  sx={{ fontSize: "30px", fontWeight: 700, marginTop: "30%" }}
                >
                  {item?.title}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "11px",
                    fontWeight: 400,
                    textAlign: "center",
                    width: "85%",
                    lineHeight: 1.1,
                  }}
                >
                  {item?.content}
                </Typography>
              </Grid>
            </Grid>
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
};

export default ResponsiveBox;
