import { Box, Grid, Typography } from "@mui/material";
import React, { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import CommonButton from "../../../../../../components/CommonButton";
import { useNavigate } from "react-router-dom";

const Responsive = ({ content }) => {
  const navigate = useNavigate();
  const swiperRef = useRef(null);
  return (
    <Box>
      <Swiper
        ref={swiperRef}
        className="custom-swiper"
        spaceBetween={10}
        slidesPerView={3}
        navigation={{
          nextEl: ".swiper-next",
          prevEl: ".swiper-prev",
        }}
        breakpoints={{
          1440: { slidesPerView: 3, spaceBetween: 50 },
          1220: { slidesPerView: 3, spaceBetween: 50 },
          1100: { slidesPerView: 3, spaceBetween: 50 },
          1024: { slidesPerView: 3, spaceBetween: 0 },
          1000: { slidesPerView: 3, spaceBetween: 0 },
          900: { slidesPerView: 3, spaceBetween: 0 },
          650: { slidesPerView: 1.6, spaceBetween: 10 },
          500: { slidesPerView: 1.5, spaceBetween: 10 },
          0: { slidesPerView: 1.1, spaceBetween: 10, dragSize: 100 },
        }}
        style={{ width: "85vw" }}
      >
        {content.map((item, index) => (
          <SwiperSlide
            key={index}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Grid container spacing={3} mt={2} justifyContent="center">
              <Grid
                item
                key={index}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    width: "90%",
                    height: "320px",
                    backgroundColor: "#EDFFB8",
                    padding: "10px",
                    borderRadius: "20px",
                    display: "flex",
                    flexDirection: "column",
                    justifyConten: "center",
                    alignItems: "center",
                    boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <img
                    src={item.img}
                    alt={item.title}
                    style={{ width: "100%", height: "65%" }}
                  />
                  <Box
                    sx={{
                      textAlign: "center",
                      width: "90%",
                      mt: 1,
                    }}
                  >
                    <Typography sx={{ fontSize: "22px", fontWeight: 600 }}>
                      {item.title}
                    </Typography>
                    <Typography sx={{ fontSize: "12px", fontWeight: 400 }}>
                      {item.desc}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: "160px",
                    borderRadius: "50px",
                    boxShadow: "0px 0px 15px #daf784",
                    marginTop: "-15px",
                  }}
                >
                  <CommonButton
                    btnClick={() => {
                      navigate("products", { state: { tabValue: item.value } });
                    }}
                    text={"See More"}
                  />
                </Box>
              </Grid>
            </Grid>
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
};

export default Responsive;
