import { Grid, Typography } from "@mui/material";
import React from "react";

const BoxLine = () => {
  return (
    <Grid
      container
      sx={{
        mt: 5,
        width: "100%",
        borderRadius: { xs: "25px", sm: "40px" },
        backgroundImage:
          "linear-gradient(to bottom right, #dff1b7,#f3fad2,#d1e8c6)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: { xs: "50px 20px", sm: "15px 60px" },
      }}
    >
      <Typography
        sx={{
          textAlign: "center",
          fontSize: { xs: "13px", sm: "14px" },
          fontWeight: 400,
        }}
      >
        These partnerships enable us to source the latest technologies and offer
        them at competitive prices, ensuring that our clients receive the best
        value and performance.
      </Typography>
    </Grid>
  );
};

export default BoxLine;
