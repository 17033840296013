import { Box, Grid, Typography } from "@mui/material";
import React from "react";

const DataSystem = () => {
  return (
    <Grid
      container
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        mt: 8,
      }}
    >
      <Box
        sx={{
          width: "90%",
          height: { xs: "70vh", sm: "250px" },
          borderRadius: { xs: "25px", md: "50px" },
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          padding: "20px",
          backgroundImage:
            "linear-gradient(to bottom right,#fbfee6, #f2fae1, #e5f7bd,#e7fca5,#e7fca5)",
        }}
      >
        <Typography
          sx={{ fontSize: { xs: "25px", sm: "35px" }, fontWeight: 600 }}
        >
          Data System
        </Typography>
        <Typography
          sx={{
            fontSize: { xs: "12px", sm: "14px" },
            fontWeight: 400,
            width: { xs: "90%", sm: "70%" },
            textAlign: "center",
          }}
        >
          At DATA SYSTEMS, we pride ourselves on delivering unparalleled
          networking and connectivity solutions to both SMBs and Corporates. Our
          commitment is to provide you with the perfect blend of cutting-edge
          equipment and a highly skilled workforce, ensuring exceptional support
          at every step of your journey.
        </Typography>
        <Typography
          sx={{
            fontSize: { xs: "12px", sm: "14px" },
            fontWeight: 400,
            width: { xs: "90%", sm: "70%" },
            textAlign: "center",
            mt: 2,
          }}
        >
          Experience the difference with DATA SYSTEMS – where your success is
          our priority.
        </Typography>
      </Box>
      <Box
        sx={{
          height: { xs: "70px", sm: "150px" },
          marginTop: { xs: "-25px", md: "-65px" },
        }}
      >
        <img src={"https://res.cloudinary.com/dm9ak3vvw/image/upload/v1730099898/DataS_mkezhh.svg"} alt="data" style={{ height: "100%", width: "100%" }} />
      </Box>
    </Grid>
  );
};

export default DataSystem;
