import { Grid } from "@mui/material";
import React from "react";
import ProductSubHead from "../../../../components/ProductSubHead";
import ProdImgBox from "../../../../components/ProductsImgBox"; 

const Storage = () => {
  const content = [
    {
      img: "https://res.cloudinary.com/dm9ak3vvw/image/upload/v1730102431/Storage1_waltix.svg",
      title: "Seagate Hard Disk",
    },
    {
      img: "https://res.cloudinary.com/dm9ak3vvw/image/upload/v1730102215/Firewall4_eft1dz.svg",
      title: "QNAP",
    },
    // {
    //   img: Storage2,
    //   title: "Aarvex SSD's",
    // },
  ];
  return (
    <Grid container mt={"5px"}>
      <Grid item xs={12}>
        <ProductSubHead
          title={"Storage & HDD Solutions"}
          description={
            "Optimize your data management with our advanced storage solutions, designed for performance and reliability. Explore our top-tier products"
          }
        />
      </Grid>
      <Grid item sx={{ width: "100%", mt: 5 }}>
        <ProdImgBox content={content} />
      </Grid>
    </Grid>
  );
};
export default Storage;
