import { Grid } from "@mui/material";
import React from "react";
import ProductSubHead from "../../../../components/ProductSubHead";
import ProdImgBox from "../../../../components/ProductsImgBox";

const ActiveWifi = () => {
  const content = [
    {
      img: "https://res.cloudinary.com/dm9ak3vvw/image/upload/v1730101112/Active5_pkly2d.svg",
      title: "Managed Switches",
    },
    {
      img: "https://res.cloudinary.com/dm9ak3vvw/image/upload/v1730101114/Active4_hnoo1q.svg",
      title: "Un- Managed Switches",
    },
    {
      img: "https://res.cloudinary.com/dm9ak3vvw/image/upload/v1730101097/Active3_fwgz6b.svg",
      title: "Routers",
    },
    {
      img: "https://res.cloudinary.com/dm9ak3vvw/image/upload/v1730101099/Active2_aridar.svg",
      title: "Access Points",
    },
    {
      img: "https://res.cloudinary.com/dm9ak3vvw/image/upload/v1730101104/Active1_wnzrpq.svg",
      title: "Media Converters",
    },
  ];
  return (
    <Grid container mt={"5px"}>
      <Grid item xs={12}>
        <ProductSubHead
          title={"Active & Wi-Fi network"}
          description={
            "Explore our comprehensive range of Active and Wi-Fi network solutions designed to elevate your connectivity and network management. Our product lineup includes:"
          }
        />
      </Grid>
      <Grid item sx={{ width: "100%", mt: 5 }}>
        <ProdImgBox content={content} />
      </Grid>
    </Grid>
  );
};

export default ActiveWifi;
