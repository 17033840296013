import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import CommonBg from "../../components/CommonBg";
import ProductsTabs from "./components/tabs";

const Products = () => {
  return (
    <Grid>
      <Container>
        <CommonBg
          isActive={true}
          image="https://res.cloudinary.com/dm9ak3vvw/image/upload/v1728505874/productsBanner_e7hito.png"
          text={"Products"}
          boxHead={"Your Source for Advanced IT Networking Solutions"}
          boxText={
            "Data Systems offers a comprehensive range of IT networking products tailored to meet the diverse needs of our clients."
          }
          Head2={"Networking Equipment"}
          Text2={
            "Routers, switches, wireless access points, networking racks, cables, and related accessories from top brands like Commscope, Netrack, Cisco, D-Link, and Digisol."
          }
        />
        <Box
          sx={{
            width: "100%",
            mt: "-20px",
            position: "relative",
            display: { xs: "none", md: "initial" },
          }}
        >
          <img src={"https://res.cloudinary.com/dm9ak3vvw/image/upload/v1730100585/Ellipse_46_duqo4a.png"} alt="ellipse" style={{ width: "100%" }} />
          <Box
            sx={{
              position: "absolute",
              top: "10px",
              left: "50%",
              transform: "translateX(-50%)",
            }}
          >
            <Typography
              sx={{ fontSize: "18px", fontWeight: 100, fontStyle: "italic" }}
            >
              <img
                src={"https://res.cloudinary.com/dm9ak3vvw/image/upload/v1730100618/Hand_caotra.svg"}
                alt="hand"
                style={{ width: "15px", marginRight: "10px" }}
              />
              Select the tab below to see more product
            </Typography>
          </Box>
        </Box>
        <ProductsTabs />
      </Container>
    </Grid>
  );
};

export default Products;
