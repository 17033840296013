import { Grid, Typography } from "@mui/material";
import React from "react";
import ProductSubHead from "../../../../components/ProductSubHead";
import ProdImgBox from "../../../../components/ProductsImgBox";

const Accessories = () => {
  const content = [
    {
      img: "https://res.cloudinary.com/dm9ak3vvw/image/upload/v1730101937/Accessories1_es8kmn.svg",
      title: "CAT 6 Faceplate Complete Keystone Jack/Module",
    },
    {
      img: "https://res.cloudinary.com/dm9ak3vvw/image/upload/v1730101952/Accessories2_tq5qig.svg",
      title: "CAT 6 KEYSTONE I/O Jack",
    },
    {
      img: "https://res.cloudinary.com/dm9ak3vvw/image/upload/v1730101963/Accessories3_m2xvjn.svg",
      title: "Shelf , PDU, Fan ,HW, Power Manager",
    },
    {
      img: "https://res.cloudinary.com/dm9ak3vvw/image/upload/v1730101938/Accessories6_bnkfvr.svg",
      title: "CAT 6 1Mtr & 2Mtr Patchcord ",
    },
    {
      img: "https://res.cloudinary.com/dm9ak3vvw/image/upload/v1730101939/Accessories4_lb45qa.svg",
      title: "Fiber Pigtails 1mtr  ",
    },
    // {
    //   img: Access5,
    //   title: "Accessories",
    // },
  ];
  return (
    <Grid container mt={"5px"}>
      <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
        <Typography sx={{ fontSize: "28px", fontWeight: 700 }}>
          Accessories
        </Typography>
      </Grid>
      <Grid item sx={{ width: "100%", mt: 5 }}>
        <ProdImgBox content={content} />
      </Grid>
    </Grid>
  );
};

export default Accessories;
