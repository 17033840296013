import { Grid } from "@mui/material";
import React from "react";
import ProductSubHead from "../../../../components/ProductSubHead";
import ProdImgBox from "../../../../components/ProductsImgBox";

const Survillience = () => {
  const content = [
    {
      img: "https://res.cloudinary.com/dm9ak3vvw/image/upload/v1730103073/Survillience1_lkavd9.svg",
      title: "IP Cameras",
    },
    {
      img: "https://res.cloudinary.com/dm9ak3vvw/image/upload/v1730103059/Survillience2_x02lkg.svg",
      title: "PTZ Cameras",
    },
    {
      img: "https://res.cloudinary.com/dm9ak3vvw/image/upload/v1730103075/Survillience3_k6deiy.svg",
      title: "Analog Cameras",
    },
  ];

  return (
    <Grid container mt={"5px"}>
      <Grid item xs={12}>
        <ProductSubHead
          title={"Surveillance"}
          description={
            "Secure your premises with our cutting-edge surveillance products, designed to offer comprehensive monitoring and peace of mind. Discover our advanced solutions"
          }
        />
      </Grid>
      <Grid item sx={{ width: "100%", mt: 5 }}>
        <ProdImgBox content={content} />
      </Grid>
    </Grid>
  );
};

export default Survillience;
