import { Grid } from "@mui/material";
import React from "react";
import ProductSubHead from "../../../../components/ProductSubHead";
import ProdImgBox from "../../../../components/ProductsImgBox";

const Racks = () => {
  const content = [
    {
      img: "https://res.cloudinary.com/dm9ak3vvw/image/upload/v1730101696/Racks1_ifbg2q.svg",
      title: "Wall Mount Rack",
    },
    {
      img: "https://res.cloudinary.com/dm9ak3vvw/image/upload/v1730101702/Racks2_pieb49.svg",
      title: "Floor Mount Rack",
    },
    {
      img: "https://res.cloudinary.com/dm9ak3vvw/image/upload/v1730101695/Racks3_msia1m.svg",
      title: "Open Rack",
    },
    {
      img: "https://res.cloudinary.com/dm9ak3vvw/image/upload/v1730101668/Racks4_ccgngw.svg",
      title: "Outdoor Rack",
    },
    {
      img: "https://res.cloudinary.com/dm9ak3vvw/image/upload/v1730101682/Racks6_fehfyc.svg",
      title: "Closed Rack",
    },
    // {
    //   img: Racks5,
    //   title: "Accessories",
    // },
  ];
  return (
    <Grid container mt={"5px"}>
      <Grid item xs={12}>
        <ProductSubHead
          title={"Passive Network Solutions"}
          description={
            "Discover our extensive selection of Passive network solutions, crafted to provide reliable, high-quality connectivity for your infrastructure needs. Our product offerings include:"
          }
        />
      </Grid>
      <Grid item sx={{ width: "100%", mt: 5 }}>
        <ProdImgBox content={content} />
      </Grid>
    </Grid>
  );
};

export default Racks;
