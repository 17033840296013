import { Divider, Grid, Typography } from "@mui/material";
import React from "react";

const ProductSubHead = ({ title, description }) => {
  return (
    <Grid
      container
      xs={12}
      sx={{
        display: "flex",
        gap: { md: "15px" },
        padding: { xs: "0px", md: "0px 10px" },
      }}
    >
      <Grid item>
        <Typography sx={{ fontSize: "28px", fontWeight: 700 }}>
          {title}
        </Typography>
      </Grid>
      <Divider
        orientation="vertical"
        flexItem
        sx={{
          display: { xs: "none", md: "initial" },
          borderRightWidth: 2,
          backgroundColor: "#000",
        }}
      />
      <Grid
        item
        sx={{
          width: { xs: "100%", md: title === "Surveillance" ? "80%" : "65%" },
        }}
      >
        <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
          {description}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ProductSubHead;
