import React from "react";
import { Box, Grid, Typography } from "@mui/material";

const WhyChoose = () => {
  const features = [
    {
      title: "Experience & Expertise",
      description:
        "With decades of experience in the industry, we have the knowledge and skills to deliver top-notch networking solutions.",
      align: "left",
      marginLeft: "350px",
      marginTop: "-60px",
    },
    {
      title: "Customer-Centric Approach",
      description:
        "Our focus is on understanding and meeting the unique needs of each client, ensuring complete satisfaction.",
      align: "right",
      marginLeft: "-350px",
      marginTop: "-60px",
    },
    {
      title: "Strong Vendor Partnerships",
      description:
        "Our alliances with leading brands allow us to offer the latest and most reliable products.",
      align: "left",
      marginLeft: "350px",
      marginTop: "-60px",
    },
    {
      title: "Comprehensive Support",
      description:
        "We provide end-to-end support, from product selection and sourcing to installation and maintenance.",
      align: "right",
      marginLeft: "-350px",
      marginTop: "-60px",
    },
  ];

  return (
    <Grid
      container
      sx={{ height: { md: "120vh" }, mt: 8, width: "100%", display: "flex" }}
    >
      <Grid
        item
        sx={{
          position: "relative",
          width: "45%",
          display: { xs: "none", md: "flex" },
          alignItems: "center",
        }}
      >
        <Box>
          <img
            src={"https://res.cloudinary.com/dm9ak3vvw/image/upload/v1730108761/solMan_nreamj.svg"}
            alt="solman"
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        </Box>
        <Box
          sx={{
            position: "absolute",
            right: "13%",
            top: "20%",
            width: 200,
            borderRadius: 2,
            p: 1,
            bgcolor: "#fff",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
          }}
        >
          <Typography fontSize={25} fontWeight={400}>
            Why Choose <br />
            <Typography component="span" fontSize={27} fontWeight={700}>
              Data Systems
            </Typography>
            ?
          </Typography>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        sx={{ display: "flex", justifyContent: "center" }}
      >
        <Box display="flex" flexDirection="column" gap={4} alignItems="center">
          {features.map((feature, index) => (
            <React.Fragment key={index}>
              <Box
                sx={{
                  width: 220,
                  p: { xs: "25px 15px", md: 1.5 },
                  borderRadius: 2.5,
                  backgroundImage:
                    "linear-gradient(to right,#c4e080, #FCFEE2, #E1FF8A,#cee7cb)",
                  textAlign: { xs: "center", md: feature.align },
                }}
              >
                <Typography fontSize={26} fontWeight={400}>
                  {feature.title}
                </Typography>
              </Box>
              <Box
                sx={{
                  width: { xs: 200, md: 230 },
                  mt: feature.marginTop,
                  ml: { xs: "0px", md: feature.marginLeft },
                  borderRadius: { xs: 3, md: 1 },
                  p: 1,
                  bgcolor: "#fff",
                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                }}
              >
                <Typography fontSize={12}>{feature.description}</Typography>
              </Box>
            </React.Fragment>
          ))}
        </Box>
      </Grid>
    </Grid>
  );
};

export default WhyChoose;
