import { Container, Grid, Typography } from "@mui/material";
import React from "react";

const LeadingVision = () => {
  const bg = "https://res.cloudinary.com/dm9ak3vvw/image/upload/v1730107393/LeadingVision_j3sems.svg"
  return (
    <Grid
      sx={{
        m: { xs: "10px 0px", md: "80px 50px" },
        height: { md: "40vh" },
        backgroundImage: `linear-gradient(to right,rgb(214,238,170,0.9),rgb(247,252,220,0.9),rgb(185,219,171,0.8) ), url(${bg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        borderRadius: { md: "25px" },
      }}
    >
      <Container
        sx={{
          width: "100%",
          display: "flex",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
          gap: "10px",
          flexDirection: "column",
          padding: "25px 10px",
        }}
      >
        <Typography
          sx={{
            fontSize: { xs: "25px", md: "30px" },
            fontWeight: 500,
            textAlign: "center",
          }}
        >
          Leading with Vision & Expertise
        </Typography>
        <Typography
          sx={{
            fontSize: { xs: "14px", md: "18px" },
            width: { md: "70%" },
            textAlign: "center",
          }}
        >
          Our team at Data Systems is led by a group of dedicated professionals
          who bring extensive experience and expertise to the table. Under the
          visionary leadership of our CEO, we have built a strong foundation
          that drives our success and innovation in the IT networking industry.
        </Typography>
      </Container>
    </Grid>
  );
};

export default LeadingVision;
