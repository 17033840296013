import { Box, Divider, Grid, Typography } from "@mui/material";
import React from "react";
import CircleIcon from "@mui/icons-material/Circle";
import CopyrightIcon from "@mui/icons-material/Copyright";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  const Parts = [
    {
      title: "Website",
      points: [
        { name: "Home", route: "/" },
        { name: "Product Categories", route: "/products" },
        { name: "About Us", route: "/about-us" },
        { name: "Brand & Alliance", route: "/brand-alliance" },
        { name: "Solutions", route: "/solutions" },
        { name: "Contact Us", route: "/contact-us" },
      ],
    },
    {
      title: "Products",
      points: [
        { name: "Passive", route: "/products" },
        { name: "Active & WiFi", route: "/products" },
        { name: "Racks", route: "/products" },
        { name: "Accessories", route: "/products" },
        { name: "Firewall & AV", route: "/products" },
        { name: "Storage & HDD", route: "/products" },
        { name: "Surveillance", route: "/products" },
      ],
    },
    {
      title: "Location",
      points: [
        { name: "Telangana", route: "https://maps.app.goo.gl/cAWZviN7qVioB4KF7" },
        { name: "Vijayawada", route: "https://maps.app.goo.gl/untLCsTnAXSr3SQ1A" },
        { name: "Vizag", route: "https://maps.app.goo.gl/Cn1RymNYb4LXXFKi9" },
        { name: "Anantapur", route: "" },
        { name: "Kakinada-Rajahmundry", route: "" },
        { name: "Odisha-Bhubaneswar", route: "" },
      ],
    },
  ];

  const Address = [
    "314, 3rd Floor Minerva Complex 94,Sarojini Devi Road Secunderabad - 500003",
    " #33-20-6, Sri Sai Nilayam, Chalasani  Venkata Krishnaiah street, Behind Bandar Mithai Sweet Shop, Suryaraopet, Vijayawada -520002",
    "49-36-8, Flat No. 101, Near Municipal High School, NGGOS Colony, Akkayyapalem, Visakhapatnam - 530016",
  ];

  const openGoogleMaps = () => {
    const googleMapsUrl = `https://maps.app.goo.gl/cAWZviN7qVioB4KF7`;
    window.open(googleMapsUrl, "_blank");
  };

  return (
    <Grid
      container
      sx={{
        width: "100%",
        backgroundImage: "linear-gradient(to bottom right, #FCFEE2, #E1FF8A)",
        mt: 5,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Grid
        item
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: { sm: "space-between" },
          width: "100%",
          gap: { xs: "40px", sm: "0px" },
          padding: { xs: "20px 30px", sm: "20px 70px" },
        }}
      >
        {Parts.map((part, index) => (
          <Box
            sx={{
              display: "flex",
            }}
          >
            <Box sx={{ fontSize: "14px" }} key={index}>
              <Typography sx={{ fontWeight: 600, marginBottom: "3px" }}>
                {part.title}
              </Typography>
              {part.points.map((point, idx) => (
                <li
                  key={idx}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if(part.title === "Location" && point.route !== "" ) {
                      window.open(point?.route, "_blank");
                    } else{
                      if(point.route === "/products") {
                        navigate(point?.route, { state: { tabValue: idx } })
                      } else if (point.route !== "") navigate(point?.route);
                    }
                  }}
                >
                  <CircleIcon
                    sx={{ fontSize: "5px", marginRight: "5px", color: "#000" }}
                  />
                  <Typography
                    sx={{ fontSize: "14px", fontWeight: 400, color: "#8B8B8B" }}
                  >
                    {point?.name}
                  </Typography>
                </li>
              ))}
            </Box>
          </Box>
        ))}
        <Box
          sx={{ width: { sm: "400px" }, height: { xs: "85px", sm: "160px" } }}
        >
          <img
            src={"https://res.cloudinary.com/dm9ak3vvw/image/upload/v1730109923/Map_hexwpk.png"}
            alt="map"
            style={{ width: "100%", height: "100%" }}
            onClick={openGoogleMaps}
          />
        </Box>
      </Grid>
      <Divider sx={{ borderBottomWidth: 3, borderColor: "#fff" }} />
      <Grid
        item
        sx={{
          padding: "20px 20px 20px 30px",
          display: { xs: "none", sm: "flex" },
          flexDirection: "column",
        }}
      >
        <Typography sx={{ fontSize: "15px", fontWeight: 600, ml: 5 }}>
          Top Branches
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "space-evenly", mt: 1 }}>
          {Address.map((item, index) => (
            <React.Fragment key={index}>
              <Box
                sx={{
                  maxWidth: "320px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Typography sx={{ fontSize: "13px" }}>Data Systems</Typography>
                <Typography sx={{ fontSize: "12px" }}>{item}</Typography>
                <Typography sx={{ fontSize: "12px" }}>
                  Contact Us- <a href="mailto:admin@datasystems.co.in">admin@datasystems.co.in</a>{" "}
                </Typography>
              </Box>
              {index < Address.length - 1 && (
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ borderWidth: 1, borderColor: "#000" }}
                />
              )}
            </React.Fragment>
          ))}
        </Box>
      </Grid>
      <Divider sx={{ borderBottomWidth: 3, borderColor: "#fff" }} />
      <Grid item sx={{ padding: "8px 65px" }}>
        <Typography sx={{ fontSize: "14px" }}>
          Copyright <CopyrightIcon fontSize="8px" /> 2024 by Data System All
          Rights Reserved
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Footer;
