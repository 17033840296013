import { Container, Grid } from "@mui/material";
import React from "react";
import CommonBg from "../../components/CommonBg";
import OurSolutions from "./components/OurSolutions";
import ConnectionPos from "./components/ConnectionPos";
import Ceo from "./components/Ceo";
import AboutTeam from "./components/AboutTeam";
import Vision from "./components/Vision";
import LeadingVision from "./components/LeadingVision";

const AboutUsPage = () => {
  return (
    <Grid
      sx={{
        padding: { xs: "0px", md: "0px 40px" },
        width: "100%",
      }}
    >
        <CommonBg
          isActive={false}
          text={"About Us"}
          image={"https://res.cloudinary.com/dm9ak3vvw/image/upload/v1730106281/aboutBg_s2gevi.svg"}
          stylePos={"bottom"}
        />
      <Container disableGutters sx={{ maxWidth: "100% !important" }}>
        <ConnectionPos />
        <OurSolutions />
        <Ceo />
        <AboutTeam />
        <Vision />
        <LeadingVision />
      </Container>
    </Grid>
  );
};

export default AboutUsPage;
