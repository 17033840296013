import React from "react";
import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import ResponsiveBox from "../responsiveBox";

const ImageBox = () => {
  const isSmall = useMediaQuery("(max-width:800px)");
  const imageBox = [
    {
      img: "https://res.cloudinary.com/dm9ak3vvw/image/upload/v1726920966/Image_10_sjatq2.svg",
      title: "Networking",
      content:
        "We offer a wide selection of enterprise networking equipment from leading brands like Commscope,Netrack, Cisco, D-Link, Digisol and more. This includes routers, switches, wireless access points, networking racks, cables and related accessories",
    },
    {
      img: "https://res.cloudinary.com/dm9ak3vvw/image/upload/v1726920965/Image_9_dswwzj.svg",
      title: "Surveillance",
      content:
        "Our Video Surveillance solutions feature high-quality cameras and NVR's from CP-Plus, Hikvision and other top brands.",
    },
    {
      img: "https://res.cloudinary.com/dm9ak3vvw/image/upload/v1726920965/Image_8_l2gdnm.svg",
      title: "Security",
      content:
        "We offer cybersecurity and data protection solutions including firewalls, VPNs, malware protection and more from leading vendors such as Sophos, Fortinet",
    },
  ];

  return isSmall ? (
    <ResponsiveBox imageBox={imageBox} />
  ) : (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-evenly",
        mt: 6,
        gap: "20px",
      }}
    >
      {imageBox.map((item, index) => (
        <Grid
          container
          key={index}
          sx={{
            width: { sm: "230px", md: "270px", lg: "320px" },
            height: { sm: "300px", md: "350px", md: "450px" },
            position: "relative",
          }}
        >
          <Grid item sx={{ width: "100%", height: "65%" }}>
            <img
              src={item?.img}
              alt={item?.title}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                borderRadius: "15px",
              }}
            />
          </Grid>
          <Grid
            item
            sx={{
              width: "100%",
              height: "35%",
              position: "absolute",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              // justifyContent: "center",
              top: "50%",
              // boxShadow: "0px -10px 10px rgba(255, 255, 255, 0.6)",
              background:
                "linear-gradient(to bottom,rgba(255, 255, 255, 0),rgba(255, 255, 255, 1), rgba(255, 255, 255, 1),rgba(255, 255, 255, 1) ,rgba(255, 255, 255, 1))",
            }}
          >
            <Typography
              sx={{
                fontSize: { sm: "30px", md: "35px" },
                fontWeight: 700,
                mt: 3,
              }}
            >
              {item?.title}
            </Typography>
            <Typography
              sx={{
                fontSize: { sm: "11px", md: "13px" },
                fontWeight: 400,
                textAlign: "center",
                width: "85%",
                lineHeight: 1.4,
              }}
            >
              {item?.content}
            </Typography>
          </Grid>
        </Grid>
      ))}
    </Box>
  );
};

export default ImageBox;
