import { Box, Toolbar, Typography, Drawer, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

const Header = () => {
  const [active, setactive] = useState(0);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const handleActiveRoute = () => {
    switch (location.pathname) {
      case "/products":
        setactive(1);
        break;
      case "/about-us":
        setactive(2);
        break;
      case "/brand-alliance":
        setactive(3);
        break;
      case "/solutions":
        setactive(4);
        break;
      case "/contact-us":
        setactive(5);
        break;
      default:
        setactive(0);
    }
  };

  useEffect(() => {
    handleActiveRoute();
  }, [location]);

  const options = [
    { label: "Home", path: "/" },
    { label: "Product Categories", path: "/products" },
    { label: "About Us", path: "/about-us" },
    { label: "Brands and Alliance", path: "/brand-alliance" },
    { label: "Solutions", path: "/solutions" },
  ];

  const handleSetActive = (index) => {
    setactive(index);
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  return (
    <Box sx={{ flexGrow: 1, marginTop: { xs: "10px", sm: "30px" } }}>
      <Toolbar>
        <Box
          sx={{
            width: "100%",
            height: { xs: "42px", md: "65px" },
            display: "inline-flex",
            justifyContent: { xs: "space-between", md: "space-evenly" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
              width: { xs: "45%", sm: "30%", md: "17%" },
              backgroundColor: "#DCFA86",
              borderRadius: "200px",
              boxShadow: "inset 0px 4px 194.8px 0px rgba(255, 255, 255, 0.73)",
            }}
            onClick={() => navigate("/")}
          >
            <img
              width={"65%"}
              height={"90%"}
              src="https://res.cloudinary.com/dm9ak3vvw/image/upload/v1728503317/datasystemsLogo_nbpyd7.svg"
              alt="Data System"
            />
          </Box>

          {/* Desktop navigation */}
          <Box
            sx={{
              height: "100%",
              width: "65%",
              display: { xs: "none", md: "inline-flex" },
              justifyContent: "space-evenly",
              color: "black",
              alignItems: "center",
              backgroundColor: "#DCFA86",
              borderRadius: "200px",
              boxShadow: "inset 0px 4px 194.8px 0px rgba(255, 255, 255, 0.73)",
            }}
          >
            {options?.map((item, index) => (
              <Link
                key={index}
                to={item.path}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <Box
                  key={index}
                  sx={{
                    width: { lg: "130px" },
                    padding: "10px",
                    borderRadius: "25px",
                    display: "flex",
                    justifyContent: "center",
                    border: active === index ? "1px solid #e4fa9c" : "none",
                    backgroundColor: active === index ? "#fff" : "transparent",
                    cursor: "pointer",
                  }}
                  onClick={() => handleSetActive(index)}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: active === index ? 600 : 500,
                      whiteSpace: "nowrap",
                    }}
                  >
                    {item.label}
                  </Typography>
                </Box>
              </Link>
            ))}
          </Box>

          <Box sx={{ display: "flex" }}>
            <Box
              sx={{
                height: "100%",
                // width: { md: "12%" },
                color: "black",
                backgroundColor: "#DCFA86",
                borderRadius: "200px",
                boxShadow:
                  "inset 0px 4px 194.8px 0px rgba(255, 255, 255, 0.73)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Link
                to="/contact-us"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <Box
                  onClick={() => {
                    setactive(5);
                  }}
                  sx={{
                    padding: "10px 18px",
                    borderRadius: "50px",
                    textAlign: "center",
                    border: active === 5 ? "1px solid #e4fa9c" : "none",
                    backgroundColor: active === 5 ? "#fff" : "transparent",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: active === 5 ? 600 : 500,
                      whiteSpace: "nowrap",
                    }}
                  >
                    Contact Us
                  </Typography>
                </Box>
              </Link>
            </Box>

            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer(true)}
              sx={{
                display: { xs: "flex", md: "none" },
                width: "40px",
                height: "100%",
                ml: "8px",
                backgroundColor: "#eefdc5",
              }}
            >
              <img
                src={"https://res.cloudinary.com/dm9ak3vvw/image/upload/v1730109931/Burger_v1uar5.svg"}
                alt="burger"
                style={{ width: "100%", height: "100%" }}
              />
            </IconButton>
          </Box>

          <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
            <Box
              sx={{ width: 250 }}
              role="presentation"
              onClick={toggleDrawer(false)}
              onKeyDown={toggleDrawer(false)}
            >
              {options?.map((item, index) => (
                <Link
                  key={index}
                  to={item.path}
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <Box
                    sx={{
                      padding: "10px",
                      borderBottom: "1px solid #ddd",
                      cursor: "pointer",
                      backgroundColor:
                        active === index ? "#f4f4f4" : "transparent",
                    }}
                    onClick={() => handleSetActive(index)}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: active === index ? 600 : 400,
                      }}
                    >
                      {item.label}
                    </Typography>
                  </Box>
                </Link>
              ))}
            </Box>
          </Drawer>
        </Box>
      </Toolbar>
    </Box>
  );
};

export default Header;
