import { Container, Grid } from "@mui/material";
import React from "react";
import TrustedPartners from "./components/TrustedPartners";
import BoxLine from "./components/BoxLine";
import CommonBg from "../../components/CommonBg";

const BrandAndAlliance = () => {
  return (
    <Grid sx={{ padding: "10px", width: "100%" }}>
      <CommonBg image="https://res.cloudinary.com/dm9ak3vvw/image/upload/v1728505951/Brand_allianceBanner_mk6jcm.png" isActive={false} text={"Brand & Alliance"} />
      <Container
        sx={{
          mt: 5,
          padding: { xs: "0px", md: "0px 20px" },
          maxWidth: "100% !important",
        }}
      >
        <TrustedPartners />
        <BoxLine />
      </Container>
    </Grid>
  );
};

export default BrandAndAlliance;
