import { Box, Grid } from "@mui/material";
import React from "react";
import HeadingLight from "../../../../components/HeadingLight";

const TrustedPartners = () => {
  const partners = [
    {img:"https://res.cloudinary.com/dm9ak3vvw/image/upload/v1730098350/image_25_yq8x1a.svg",link:"https://www.dlink.com/in/en"},
    {img:"https://res.cloudinary.com/dm9ak3vvw/image/upload/v1730098439/image_26_skfz4m.svg",link:"http://sophos.com/"},
    {img: "https://res.cloudinary.com/dm9ak3vvw/image/upload/v1730098428/image_27_oyj6ln.svg", link: "http://tp-link.com/" },
    {img:"https://res.cloudinary.com/dm9ak3vvw/image/upload/v1730098431/image_28_ypkyjh.svg",link:"https://www.commscope.com/"},
    {img:"https://res.cloudinary.com/dm9ak3vvw/image/upload/v1730098430/image_30_cmrbwy.svg",link:"https://www.netrackindia.com/"},
    {img:"https://res.cloudinary.com/dm9ak3vvw/image/upload/v1730098433/image_32_gizto0.svg",link:"https://www.cpplusworld.com/"},
    {img:"https://res.cloudinary.com/dm9ak3vvw/image/upload/v1730098432/image_33_ncetkm.svg",link:"https://www.digisol.com/"},
    {img:"https://res.cloudinary.com/dm9ak3vvw/image/upload/v1730098435/image_35_yghy67.svg",link:"http://fluke.com/"},
    {img: "https://res.cloudinary.com/dm9ak3vvw/image/upload/v1730098437/image_36_fsb3db.svg", link: "https://www.seagate.com/in/en/" },
    {img: "https://www.numericups.com/themes/numeric/logo.svg", link: "https://www.numericups.com/" },
  ];

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <HeadingLight
          head={"Trusted Partnerships for Quality and Innovation"}
          text={
            "At Data Systems, we believe in building strong relationships with industry-leading brands to provide the best solutions to our clients. Some of our key alliances include"
          }
        />
      </Grid>
      <Grid container item xs={12} spacing={{ md: 5 }} justifyContent="center">
        {partners.map((partner, index) => (
          <Grid
            item
            xs={6}
            sm={6}
            md={3}
            key={index}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100px",
                width: "200px",
                cursor:"pointer"
              }}
              onClick={()=>window.open(partner.link,"_blank", "noopener,noreferrer")}
            >
              <img
                src={partner.img}
                alt={`partner-${index + 1}`}
                style={{ width: "100%", height: "100%" }}
              />
            </Box>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default TrustedPartners;
