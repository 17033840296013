import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import CommonButton from "../../../../components/CommonButton";

const ConnectionPos = () => {
  return (
    <Grid
      container
      sx={{
        marginTop: "30px",
        width: "100%",
        display: { md: "flex" },
        flexDirection: { xs: "column", md: "none " },
        alignItems: "center",
        height: { md: "60vh" },
      }}
    >
      <Grid
        item
        sx={{
          width: "50%",
          height: "100%",
          position: "relative",
          display: { xs: "none", md: "flex" },
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src={"https://res.cloudinary.com/dm9ak3vvw/image/upload/v1730107003/SolConnect_di69lc.svg"}
          alt="connPos"
          style={{ width: "70%", height: "90%", objectFit: "cover" }}
        />
        <Box
          sx={{
            boxShadow: "0px 0px 15px #daf784",
            width: "30%",
            borderRadius: "20px",
            position: "absolute",
            bottom: "26%",
            right: "15%",
          }}
        >
          <CommonButton text={"See more"} />
        </Box>
      </Grid>
      <Grid
        item
        sx={{
          width: { xs: "90%", md: "50%" },
          display: "flex",
          flexDirection: "column",
          alignItems: { xs: "center", md: "flex-start" },
        }}
      >
        <Typography
          sx={{
            fontSize: { xs: "30px", md: "45px" },
            fontWeight: 400,
          }}
        >
          Connecting Possibilities, Empowering Futures
        </Typography>
        <Typography
          sx={{
            fontSize: { xs: "15px", md: "17px" },
            fontWeight: 400,
            width: "100%",
          }}
        >
          Since 1984, Data Systems has been a pioneer in providing cutting-edge
          networking and security solutions. Our mission is to optimize business
          processes by enhancing scalability, reliability, and availability. We
          partner with leading hardware and software vendors to offer the best
          products and solutions to our clients through a network of partners,
          system integrators, dealers, and resellers.
        </Typography>
      </Grid>
      <Grid
        item
        sx={{
          width: "100%",
          height: "100%",
          position: "relative",
          display: { xs: "flex", md: "none" },
          alignItems: "center",
        }}
      >
        <img
          src={"https://res.cloudinary.com/dm9ak3vvw/image/upload/v1730107003/SolConnect_di69lc.svg"}
          alt="connPos"
          style={{
            width: "70%",
            height: "90%",
            objectFit: "cover",
            marginLeft: "25px",
          }}
        />
        <Box
          sx={{
            boxShadow: "0px 0px 15px #daf784",
            width: "45%",
            borderRadius: "20px",
            position: "absolute",
            bottom: "30%",
            right: "5%",
          }}
        >
          <CommonButton text={"See more"} />
        </Box>
      </Grid>
    </Grid>
  );
};

export default ConnectionPos;
