import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import ProductSubHead from "../../../../components/ProductSubHead";
import ProdImgBox from "../../../../components/ProductsImgBox";

const Passive = () => {
  const content = [
    {
      img: "https://res.cloudinary.com/dm9ak3vvw/image/upload/v1730100794/Passive2_v2qcaw.svg",
      title: "Fiber Cables",
    },
    {
      img: "https://res.cloudinary.com/dm9ak3vvw/image/upload/v1730100792/Passive1_oeaygv.svg",
      title: "Indoor & Outdoor Cables",
    },
    {
      img: "https://res.cloudinary.com/dm9ak3vvw/image/upload/v1730100846/Passive3_g3kwgw.svg",
      title: "CAT 6 UTP Cables",
    },
    {
      img: "https://res.cloudinary.com/dm9ak3vvw/image/upload/v1730100863/Passive4_nqiisk.svg",
      title: "CAT 6 UTP Outdoor Cables",
    },
    {
      img: "https://res.cloudinary.com/dm9ak3vvw/image/upload/v1730100806/Passive5_fic5wk.svg",
      title: "CAT 6 UTP Armored Cables",
    },
    {
      img: "https://res.cloudinary.com/dm9ak3vvw/image/upload/v1730100796/Passive6_ahjlgo.svg",
      title: "CAT 6 SFTP Cables",
    },
    {
      img: "https://res.cloudinary.com/dm9ak3vvw/image/upload/v1730100799/Passive7_ojn3ob.svg",
      title: "CAT 6A UTP Cables",
    },
    {
      img: "https://res.cloudinary.com/dm9ak3vvw/image/upload/v1730100809/Passive8_rx5f1t.svg",
      title: "CAT 7 Cables",
    },
  ];

  return (
    <Grid mt={"5px"}>
      <Grid item xs={12}>
        <ProductSubHead
          title={"Passive Network Solutions"}
          description={
            "Discover our extensive selection of Passive network solutions, crafted to provide reliable, high-quality connectivity for your infrastructure needs. Our product offerings include:"
          }
        />
      </Grid>
      <Grid item sx={{ width: "100%", mt: 5 }}>
        <ProdImgBox content={content} />
      </Grid>
    </Grid>
  );
};

export default Passive;
