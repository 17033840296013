import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";

const AboutTeam = () => {
  return (
    <Grid
      sx={{ padding: { md: "30px 40px" }, height: { xs: "100vh", md: "60vh" } }}
    >
      <Container
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          backgroundImage:
            "linear-gradient(to right,#c4e080, #FCFEE2, #E1FF8A,#cee7cb )",
          borderRadius: { md: "25px" },
          padding: "0px",
        }}
      >
        <Grid
          item
          sx={{
            width: { xs: "100%", md: "45%" },
            height: { xs: "280px", md: "100%" },
            backgroundColor: { xs: "#fff", md: "transparent" },
          }}
        >
          <img
            src={"https://res.cloudinary.com/dm9ak3vvw/image/upload/v1730107285/aboutTeam_n8uctk.svg"}
            alt="ceo"
            style={{ width: "100%", height: "100%" }}
          />
        </Grid>
        <Grid
          item
          sx={{
            width: { xs: "100%", md: "50%" },
            height: { xs: "55vh", md: "100%" },
            padding: { md: "0px 20px" },
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: { xs: "73%", md: "53%" },
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              padding: { md: "20px" },
            }}
          >
            <Typography sx={{ fontSize: { xs: "30px", md: "35px" } }}>
              About the Team
            </Typography>
            <Typography
              sx={{ fontSize: { xs: "14px", md: "17px" }, width: "90%", mt: 1 }}
            >
              Our team consists of seasoned professionals with diverse expertise
              in networking, cybersecurity, and IT solutions. Each member is
              committed to delivering exceptional service and support to our
              clients.
            </Typography>
          </Box>
          <Typography
            sx={{
              fontSize: { xs: "70px", md: "100px" },
              padding: "0px 10px",
              fontWeight: 600,
              color: "#fff",
              mt: { md: "-10px" },
            }}
          >
            Team
          </Typography>
        </Grid>
      </Container>
    </Grid>
  );
};

export default AboutTeam;
