import { Box, Grid, Typography } from "@mui/material";
import React, { useState } from "react";

const OurStrength = () => {
  // State to track the hovered image's content (title and desc)
  const [hoveredContent, setHoveredContent] = useState({
    title: "Logistic And Warehouse",
    desc: "We give LOGISTICS support to our partners across country wherever required.",
  });

  const content = [
    {
      img: "https://res.cloudinary.com/dm9ak3vvw/image/upload/v1730109091/Solimg1_dtlzv1.svg",
      title: "Logistic And Warehouse",
      desc: "We give LOGISTICS support to our partners across country wherever required.",
    },
    {
      img: "https://res.cloudinary.com/dm9ak3vvw/image/upload/v1730109095/Solimg2_tnfnxg.svg",
      title: "Credit Support",
      desc: "We offer credit support to our partners whenever needed.",
    },
    {
      img: "https://res.cloudinary.com/dm9ak3vvw/image/upload/v1730109099/Solimg3_p52lbz.svg",
      title: "Our partners keep coming back",
      desc: "We increased customer retention due to maintaining healthy rapport with our partners.",
    },
    {
      img: "https://res.cloudinary.com/dm9ak3vvw/image/upload/v1730109102/Solimg4_n3eyhm.svg",
      title: "Our team is growing",
      desc: "Each member is committed to delivering exceptional service and support to our clients.",
    },
    {
      img: "https://res.cloudinary.com/dm9ak3vvw/image/upload/v1730109106/Solimg5_bkq9tq.svg",
      title: "Excellence & Innovation",
      desc: "Together, we will build a connected world where possibilities are limitless, and every challenge is met with groundbreaking solutions.",
    },
    {
      img: "https://res.cloudinary.com/dm9ak3vvw/image/upload/v1730109002/Solimg6_q4kw7v.svg",
      title: "Incentives & Schemes",
      desc: "We incentivize our volume & small partners by yearly schemes and various gifting or voucher systems.",
    },
    {
      img: "https://res.cloudinary.com/dm9ak3vvw/image/upload/v1730109052/Solimg7_fmiaqi.svg",
      title: "Strong Tech Support",
      desc: "We provide efficient and strong technical support.",
    },
    {
      img: "https://res.cloudinary.com/dm9ak3vvw/image/upload/v1730109088/Solimg8_puxwm7.svg",
      title: "Socializing & Broadcasting",
      desc: "You can easily find us on emerging social media platforms such as Google, WhatsApp, Facebook, and LinkedIn.",
    },
  ];

  return (
    <Grid
      container
      sx={{
        position: "relative",
        justifyContent: "center",
        alignItems: "center",
        height: "500px",
      }}
    >
      <Box
        sx={{
          width: "220px",
          height: "220px",
          backgroundImage:
            "linear-gradient(to bottom , #E1F6A1, #DCF480, #AED7A5)",
          borderRadius: "50%",
          zIndex: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          padding: "20px",
        }}
      >
        <Typography sx={{ fontWeight: 800, fontSize: "25px" }}>
          {hoveredContent.title}
        </Typography>
        <Typography sx={{ fontSize: "13px" }}>
          {hoveredContent.desc}
        </Typography>
      </Box>

      {/* Images surrounding the center box */}
      {content.map((item, index) => (
        <Box
          key={index}
          sx={{
            position: "absolute",
            width: "115px",
            height: "115px",
            borderRadius: "50%",
            border: "2px solid white",
            transform: `rotate(${(index * 360) / content.length}deg) translate(230px) rotate(${
              -(index * 360) / content.length
            }deg)`,
            zIndex: 0,
          }}
          onMouseEnter={() =>
            setHoveredContent({ title: item.title, desc: item.desc })
          }
        >
          <img
            src={item.img}
            alt={item.title}
            style={{ width: "100%", height: "100%" }}
          />
        </Box>
      ))}
    </Grid>
  );
};

export default OurStrength;
