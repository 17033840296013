import { Grid, Typography } from "@mui/material";
import React from "react";
import ResponsiveStrength from "./components/responsive";
import OurStrength from "./components/OurStrength";

const OurStrengths = () => {
  return (
    <Grid
      container
      xs={12}
      sx={{
        display: "flex",
        height: { md: "90vh" },
        justifyContent: "space-between",
        mt: 5,
      }}
    >
      <Grid
        item
        sx={{
          width:{xs:"100%",md:"40%"},
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          padding: { xs: "0px", md: "0px 60px" },
        }}
      >
        <Typography
          sx={{ fontSize: { xs: "25px", md: "40px" }, fontWeight: 400 }}
        >
          our core
        </Typography>
        <Typography
          sx={{ fontSize: { xs: "35px", md: "60px" }, fontWeight: 600 }}
        >
          Strengths
        </Typography>
        <Typography
          sx={{ fontSize: "12px", fontStyle: "italic", fontWeight: 600 }}
        >
          *Hover on icon to know more
        </Typography>
      </Grid>
      <Grid
        item
        
        sx={{width:"60%", height: "100%", display: { xs: "none", md: "initial" } }}
      >
        <OurStrength/>
      </Grid>
      <Grid
        item
        sx={{
          display: { md: "none" },
          width: "100%",
        }}
      >
        <ResponsiveStrength />
      </Grid>
    </Grid>
  );
};

export default OurStrengths;
