import React, { useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Passive from "../Passive";
import ActiveWifi from "../Active&Wifi";
import Racks from "../Racks";
import Accessories from "../Accessories";
import Firewall from "../Firewall";
import Storage from "../Storage";
import Survillience from "../Survillience";
import { useMediaQuery } from "@mui/material";
import { useLocation } from "react-router-dom";

const CustomTabPanel = ({ children, value, index, ...other }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: { xs: 1, md: 3 } }}>{children}</Box>}
    </div>
  );
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const ProductsTabs = () => {
  const location = useLocation();
  const { tabValue } = location.state || {};

  const isSmall = useMediaQuery("(max-width: 900px)");
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const tabs = [
    { label: "Passive", content: <Passive /> },
    { label: "Active & Wi-fi", content: <ActiveWifi /> },
    { label: "Racks", content: <Racks /> },
    { label: "Accessories", content: <Accessories /> },
    { label: "Firewall & AV", content: <Firewall /> },
    { label: "Storage & HDD", content: <Storage /> },
    { label: "Surveillance", content: <Survillience /> },
  ];

  useEffect(()=>{
    if(tabValue > 0) {
      setValue(tabValue);
    }
  },[tabValue])

  return (
    <Box sx={{ width: "100%", mt: 3 }}>
      {isSmall ? (
        <Autocomplete
          value={tabs[value].label}
          onChange={(event, newValue) => {
            const selectedIndex = tabs.findIndex(
              (tab) => tab.label === newValue
            );
            setValue(selectedIndex);
          }}
          options={tabs.map((tab) => tab.label)}
          disableClearable
          disablePortal
          sx={{
            width: "100%",
            marginBottom: 2,
            "& .MuiOutlinedInput-root": {
              borderRadius: "50px",
              backgroundColor: "#e9f6bb",
              color: "#000",
              "& .MuiSvgIcon-root": {
                color: "#000",
              },
            },
            "& .MuiAutocomplete-endAdornment": {
              backgroundImage: "linear-gradient(to right, #BFE338, #AED7A5)",
              borderRadius: "50%",
              padding: "5px",
            },
            "& .MuiSvgIcon-root": {
              background: "none",
            },
            "& .MuiOutlinedInput-root .MuiSvgIcon-root": {
              color: "#fff",
              fontSize: "30px",
            },
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              sx={{
                "& .MuiInputLabel-root": {
                  fontSize: "18px",
                },
                "& .MuiInputBase-input": {
                  fontSize: "18px",
                },
              }}
            />
          )}
        />
      ) : (
        <Box sx={{ display: { xs: "none", md: "initial" } }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            TabIndicatorProps={{
              sx: {
                display: "none",
              },
            }}
          >
            {tabs.map((tab, index) => (
              <Tab
                key={index}
                label={tab.label}
                {...a11yProps(index)}
                sx={{
                  backgroundColor: value === index ? "#e9f6bb" : "#ededed",
                  color: value === index ? "#000" : "#000",
                  borderRadius: "50px",
                  padding: "0px 24px",
                  fontSize: "17px",
                  marginRight: index !== tabs.length - 1 ? "10px" : "0",
                }}
              />
            ))}
          </Tabs>
        </Box>
      )}
      {tabs.map((tab, index) => (
        <CustomTabPanel key={index} value={value} index={index}>
          {tab.content}
        </CustomTabPanel>
      ))}
    </Box>
  );
};

export default ProductsTabs;
