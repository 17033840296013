import { Box, Grid, Typography } from "@mui/material";
import React, { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

const ResponsiveStrength = () => {
  const swiperRef = useRef(null);
  const textSwiperRef = useRef(null); // Ref for the text swiper

  const handleNextClick = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const handlePrevClick = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const handleMouseMove = (index) => {
    if (textSwiperRef.current && textSwiperRef.current.swiper) {
      textSwiperRef.current.swiper.slideTo(index);
    }
  };

  const content = [
    {
      img: "https://res.cloudinary.com/dm9ak3vvw/image/upload/v1730109091/Solimg1_dtlzv1.svg",
      title: "Logistic And Warehouse",
      desc: "We give LOGISTICS support to our partners across country wherever required.",
    },
    {
      img: "https://res.cloudinary.com/dm9ak3vvw/image/upload/v1730109095/Solimg2_tnfnxg.svg",
      title: "Credit Support",
      desc: "We offer credit support to our partners whenever needed.",
    },
    {
      img: "https://res.cloudinary.com/dm9ak3vvw/image/upload/v1730109099/Solimg3_p52lbz.svg",
      title: "Our partners keep coming back",
      desc: "We increased customer retention due to maintaining healthy rapport with our partners.",
    },
    {
      img: "https://res.cloudinary.com/dm9ak3vvw/image/upload/v1730109102/Solimg4_n3eyhm.svg",
      title: "Our team is growing",
      desc: "Each member is committed to delivering exceptional service and support to our clients.",
    },
    {
      img: "https://res.cloudinary.com/dm9ak3vvw/image/upload/v1730109106/Solimg5_bkq9tq.svg",
      title: "Excellence & Innovation",
      desc: "Together, we will build a connected world where possibilities are limitless, and every challenge is met with groundbreaking solutions.",
    },
    {
      img: "https://res.cloudinary.com/dm9ak3vvw/image/upload/v1730109002/Solimg6_q4kw7v.svg",
      title: "Incentives & Schemes",
      desc: "We incentivize our volume & small partners by yearly schemes and various gifting or voucher systems.",
    },
    {
      img: "https://res.cloudinary.com/dm9ak3vvw/image/upload/v1730109052/Solimg7_fmiaqi.svg",
      title: "Strong Tech Support",
      desc: "We provide efficient and strong technical support.",
    },
    {
      img: "https://res.cloudinary.com/dm9ak3vvw/image/upload/v1730109088/Solimg8_puxwm7.svg",
      title: "Socializing & Broadcasting",
      desc: "You can easily find us on emerging social media platforms such as Google, WhatsApp, Facebook, and LinkedIn.",
    },
  ];

  // const content = [
  //   {
  //     img: img1,
  //     title: "Logistic And Warehouse",
  //     desc: "We give LOGISTICS support to our partners across country wherever required.",
  //   },
  //   {
  //     img: img2,
  //     title: "Credit Support",
  //   },
  //   {
  //     img: img3,
  //     title: "Our partners keep coming back",
  //     desc: "We increased customer retention due to maintaining healthy rapo with our partners.",
  //   },
  //   {
  //     img: img4,
  //     title: "Our team is growing",
  //     desc: "We welcomed 3 new team members last quarter",
  //   },
  //   {
  //     img: img5,
  //     title: "Our business is good",
  //     desc: "Profits are up in the last quarter by 3%",
  //   },
  //   {
  //     img: img6,
  //     title: "Incentives & Schemes",
  //     desc: "We Incentivize our Volume & small partners by yearly schemes and various gifting or voucher system at our organization",
  //   },
  //   {
  //     img: img7,
  //     title: "Strong Tech Support",
  //     desc: "We are much more efficient in providing stronger technical support",
  //   },
  //   {
  //     img: img8,
  //     title: "Socializing & Broadcasting",
  //     desc: "You can easily find us on most emerging social media platforms such as google , wats aap , FB & linkedIn  ",
  //   },
  // ];

  return (
    <Box sx={{ width: "100%" }}>
      {/* First Swiper for Images */}
      <Swiper
        ref={swiperRef}
        className="custom-swiper"
        spaceBetween={10}
        slidesPerView={3}
        navigation={{
          nextEl: ".swiper-next",
          prevEl: ".swiper-prev",
        }}
        breakpoints={{
          900: { slidesPerView: 6, spaceBetween: 0 },
          650: { slidesPerView: 5.3, spaceBetween: 10 },
          500: { slidesPerView: 3.5, spaceBetween: 10 },
          0: { slidesPerView: 2.5, spaceBetween: 10, dragSize: 100 },
        }}
        style={{ width: "85vw" }}
      >
        {content.map((item, index) => (
          <SwiperSlide
            key={index}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
            onMouseMove={() => handleMouseMove(index)} // Trigger swipe on mouse move
          >
            <Grid container spacing={3} mt={2} justifyContent="center">
              <Grid
                item
                key={index}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    border: "1.5px solid #000",
                    width: "80px",
                    height: "80px",
                    padding: "10px",
                    borderRadius: "10px",
                    display: "flex",
                    justifyConten: "center",
                    alignItems: "center",
                    boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <img
                    src={item?.img}
                    alt={item?.img}
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </SwiperSlide>
        ))}
      </Swiper>

      {/* Navigation Buttons */}
      <Box
        sx={{
          overflow: "visible",
          display: "flex",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Box
          className="swiper-prev"
          sx={{
            color: "#fff",
            cursor: "pointer",
            padding: "3px",
            backgroundImage: "linear-gradient(to bottom , #bde141, #b1d993)",
            borderRadius: "100px",
            display: "flex",
            alignItems: "center",
            mr: { xs: "-25px", sm: "0px" },
            zIndex: 999,
            mt: 3,
          }}
          onClick={handlePrevClick}
        >
          <KeyboardArrowLeftIcon fontSize="large" />
        </Box>

        {/* Second Swiper for Text */}
        <Swiper
          ref={textSwiperRef} // Second Swiper reference
          className="custom-swiper"
          spaceBetween={10}
          slidesPerView={3}
          navigation={{
            nextEl: ".swiper-next",
            prevEl: ".swiper-prev",
          }}
          breakpoints={{
            900: { slidesPerView: 3, spaceBetween: 0 },
            650: { slidesPerView: 2.3, spaceBetween: 10 },
            500: { slidesPerView: 1.5, spaceBetween: 10 },
            0: { slidesPerView: 1, spaceBetween: 10, dragSize: 100 },
          }}
          style={{ width: "85vw" }}
        >
          {content.map((item, index) => (
            <SwiperSlide
              key={index}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Grid container spacing={3} mt={2} justifyContent="center">
                <Grid
                  item
                  key={index}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      backgroundImage:
                        "linear-gradient(to right, #e5f5c4, #f8fde3, #cee7c6)",
                      width: "220px",
                      height: "220px",
                      padding: "10px 20px",
                      borderRadius: "20px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "30px",
                        fontWeight: 600,
                        textAlign: "center",
                      }}
                    >
                      {item?.title}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: 400,
                        textAlign: "center",
                      }}
                    >
                      {item?.desc}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </SwiperSlide>
          ))}
        </Swiper>

        <Box
          className="swiper-next"
          sx={{
            color: "#fff",
            cursor: "pointer",
            padding: "3px",
            backgroundImage: "linear-gradient(to bottom , #bde141, #b1d993)",
            borderRadius: "100px",
            display: "flex",
            alignItems: "center",
            ml: { xs: "-25px", sm: "0px" },
            zIndex: 999,
            mt: 3,
          }}
          onClick={handleNextClick}
        >
          <KeyboardArrowRightIcon fontSize="large" />
        </Box>
      </Box>
    </Box>
  );
};

export default ResponsiveStrength;
