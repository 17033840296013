import { Box, Grid, Typography } from "@mui/material";
import React from "react";

const OurSolutions = () => {
  return (
    <Grid
      container
      sx={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-evenly",
        height: "60vh",
        mt: 5,
      }}
    >
      <Grid
        item
        sx={{
          width: { xs: "90%", md: "40%" },
          height: "100%",
          display: "flex",
          justifyContent: { xs: "center", md: "flex-end" },
        }}
      >
        <Box>
          <Typography
            sx={{ fontSize: { xs: "30px", md: "45px" }, fontWeight: 400 }}
          >
            Our Solution
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: "15px", md: "17px" },
              fontWeight: 400,
              width: "95%",
            }}
          >
            Our streamlined solutions in sourcing, warehousing, and distribution
            ensure that we meet the needs of our clients efficiently and
            effectively. With state-of-the-art infrastructure and strong vendor
            partnerships, we guarantee timely deliveries and secure storage of
            IT equipment across India
          </Typography>
        </Box>
      </Grid>
      <Grid
        item
        sx={{
          width: "50%",
          height: "100%",
          display: { xs: "none", md: "flex" },
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src={"https://res.cloudinary.com/dm9ak3vvw/image/upload/v1730107238/SolutionsImg_m9eoos.svg"}
          alt="connPos"
          style={{ width: "90%", height: "100%" }}
        />
      </Grid>
    </Grid>
  );
};

export default OurSolutions;
