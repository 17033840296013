import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import HeadingLight from "../../../../components/HeadingLight";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

const ItSolutions = () => {
  return (
    <Grid
      container
      sx={{ width: "100%", display: "flex", justifyContent: "center" }}
    >
      <Grid
        item
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <HeadingLight
          head={"Tailored IT Solutions to Meet Your Unique Needs"}
          text={
            "Data Systems specializes in providing customized IT solutions that cater to the specific requirements of our clients. Our expertise spans across:"
          }
        />
      </Grid>
      <Grid
        item
        sx={{
          mt: 4,
          width: "100%",
          padding: { md: "20px" },
          display: "flex",
          gap: { xs: "10px", md: "20px" },
          flexDirection: "column",
          alignItems: { xs: "flex-end", md: "center" },
          justifyContent: "space-evenly",
          position: "relative",
        }}
      >
        <Box
          sx={{
            padding: { md: "10px" },
            width: { xs: "80%", md: "90%" },
            height: { xs: "150px", md: "200px" },
            borderRadius: "25px",
            display: "flex",
            backgroundImage: {
              xs: "none",
              md: "linear-gradient(to right, #eff8da, #fafeee, #e5f2e2)",
            },
          }}
        >
          <Box
            sx={{
              width: "50%",
              height: "100%",
              display: { xs: "none", md: "flex" },
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <img
              src={"https://res.cloudinary.com/dm9ak3vvw/image/upload/v1730108211/sol1_gprpbz.svg"}
              alt="sol1"
              style={{
                width: "90%",
                height: "90%",
                objectFit: "cover",
                borderRadius: "20px",
              }}
            />
          </Box>
          <Box
            sx={{
              width: { xs: "95%", md: "50%" },
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              padding: { xs: "0px", md: "0px 60px" },
            }}
          >
            <Typography
              sx={{ fontSize: { xs: "25px", md: "40px" }, fontWeight: 400 }}
            >
              Networking
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: "13px", md: "15px" },
                fontWeight: 400,
                width: { xs: "100%", md: "90%" },
              }}
            >
              We provide a wide selection of enterprise networking equipment,
              including managed, unmanaged, and semi-managed switches, GIGA
              switches, POE switches, routers, and access points.
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            padding: { md: "10px" },
            width: { xs: "80%", md: "90%" },
            height: { xs: "150px", md: "200px" },
            borderRadius: "25px",
            display: "flex",
          }}
        >
          <Box
            sx={{
              width: { xs: "95%", md: "40%" },
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              textAlign: { xs: "left", md: "right" },
              padding: { xs: "0px", md: "0px 60px" },
            }}
          >
            <Typography
              sx={{ fontSize: { xs: "25px", md: "40px" }, fontWeight: 400 }}
            >
              Surveillance
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: "13px", md: "15px" },
                fontWeight: 400,
                textAlign: { xs: "left", md: "right" },
              }}
            >
              Our video surveillance solutions include a variety of IP, analog,
              and PTZ cameras, ensuring comprehensive security coverage.
            </Typography>
          </Box>
          <Box
            sx={{
              width: "50%",
              height: "100%",
              display: { xs: "none", md: "flex" },
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <img
              src={"https://res.cloudinary.com/dm9ak3vvw/image/upload/v1730108209/sol2_r8pln1.svg"}
              alt="sol2"
              style={{
                width: "85%",
                height: "90%",
                objectFit: "cover",
                borderRadius: "20px",
              }}
            />
          </Box>
        </Box>
        <Box
          sx={{
            padding: { md: "10px" },
            width: { xs: "80%", md: "90%" },
            height: { xs: "150px", md: "200px" },
            borderRadius: "25px",
            display: "flex",
            backgroundImage: {
              xs: "none",
              md: "linear-gradient(to right, #eff8da, #fafeee, #e5f2e2)",
            },
          }}
        >
          <Box
            sx={{
              width: "50%",
              height: "100%",
              display: { xs: "none", md: "flex" },
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <img
              src={"https://res.cloudinary.com/dm9ak3vvw/image/upload/v1730108252/sol3_cyntgb.svg"}
              alt="sol3"
              style={{
                width: "90%",
                height: "90%",
                objectFit: "cover",
                borderRadius: "20px",
              }}
            />
          </Box>
          <Box
            sx={{
              width: { xs: "95%", md: "50%" },
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              padding: { xs: "0px", md: "0px 60px" },
            }}
          >
            <Typography
              sx={{ fontSize: { xs: "25px", md: "40px" }, fontWeight: 400 }}
            >
              Security
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: "13px", md: "15px" },
                fontWeight: 400,
                width: { xs: "100%", md: "90%" },
              }}
            >
              We offer advanced cybersecurity solutions, including firewalls,
              endpoint protection, and rapid response services to safeguard your
              digital assets.
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            height: "65%",
            width: "1px",
            position: "absolute",
            left: { xs: "5%", md: "48%" },
            bgcolor: "black",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <FiberManualRecordIcon
            sx={{ fontSize: "32px", color: "#BDFF00", marginTop: "-15px" }}
          />
          <FiberManualRecordIcon sx={{ fontSize: "32px", color: "#BDFF00" }} />
          <FiberManualRecordIcon
            sx={{ fontSize: "32px", color: "#BDFF00", marginBottom: "-15px" }}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default ItSolutions;
