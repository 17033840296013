import { Box, Grid } from "@mui/material";
import React, { useRef } from "react";
import Heading from "../../../../components/Headings";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

const Achievements = () => {
  const swiperRef = useRef(null);

  const images = ["https://res.cloudinary.com/dm9ak3vvw/image/upload/v1730100150/image_49_dpywmx.svg", "https://res.cloudinary.com/dm9ak3vvw/image/upload/v1730100123/image_50_debtup.svg", "https://res.cloudinary.com/dm9ak3vvw/image/upload/v1730100087/image_51_nz0cap.svg", "https://res.cloudinary.com/dm9ak3vvw/image/upload/v1730100150/image_49_dpywmx.svg"];

  const handleNextClick = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const handlePrevClick = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  return (
    <Grid
      container
      mt={6}
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Grid item>
        <Heading head={"Achievements"} text={""} />
      </Grid>
      <Grid item>
        <Box
          sx={{
            overflow: "visible",
            padding: "40px 0",
            display: { sm: "flex" },
            alignItems: "center",
            gap: { sm: "10px" },
          }}
        >
          <Box
            className="swiper-prev"
            sx={{
              color: "#000",
              cursor: "pointer",
              padding: { sm: "7px" },
              backgroundColor: "#DBFF75",
              borderRadius: "50%",
              display: { xs: "none", sm: "initial" },
            }}
            onClick={handlePrevClick}
          >
            <KeyboardArrowLeftIcon fontSize="large" />
          </Box>
          <Swiper
            ref={swiperRef}
            className="custom-swiper"
            spaceBetween={10}
            slidesPerView={3}
            navigation={{
              nextEl: ".swiper-next",
              prevEl: ".swiper-prev",
            }}
            breakpoints={{
              1440: { slidesPerView: 3, spaceBetween: 30 },
              1220: { slidesPerView: 3, spaceBetween: 15 },
              1100: { slidesPerView: 3, spaceBetween: 30 },
              1024: { slidesPerView: 3, spaceBetween: 0 },
              1000: { slidesPerView: 3, spaceBetween: 0 },
              900: { slidesPerView: 3, spaceBetween: 10 },
              768: { slidesPerView: 3, spaceBetween: 10 },
              500: { slidesPerView: 2.5, spaceBetween: 10 },
              0: { slidesPerView: 1.3, spaceBetween: 10, dragSize: 100 },
            }}
            style={{ width: "80vw" }}
          >
            {images.map((img, index) => (
              <SwiperSlide key={index}>
                <Box
                  sx={{
                    width: { xs: "100%", sm: "180px",md:"330px" },
                    height: { xs: "220px", sm: "250px",md:"330px" },
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#f0f0f0",
                    borderRadius: "20px",
                    overflow: "hidden",
                  }}
                >
                  <img
                    src={img}
                    alt={`Achievement ${index + 1}`}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                </Box>
              </SwiperSlide>
            ))}
          </Swiper>
          <Box
            className="swiper-next"
            sx={{
              color: "#000",
              cursor: "pointer",
              padding: { sm: "5px" },
              backgroundColor: "#DBFF75",
              borderRadius: "50%",
              display: { xs: "none", sm: "initial" },
            }}
            onClick={handleNextClick}
          >
            <KeyboardArrowRightIcon fontSize="large" />
          </Box>
          <Box
            sx={{
              width: "100%",
              display: { xs: "flex", sm: "none" },
              alignItems: "center",
              justifyContent: "center",
              mt: 2,
              gap: "20px",
            }}
          >
            <Box
              className="swiper-prev"
              sx={{
                color: "#000",
                cursor: "pointer",
                padding: { sm: "5px" },
                backgroundColor: "#DBFF75",
                borderRadius: "50%",
              }}
              onClick={handlePrevClick}
            >
              <KeyboardArrowLeftIcon fontSize="large" />
            </Box>
            <Box
              className="swiper-next"
              sx={{
                color: "#000",
                cursor: "pointer",
                padding: { sm: "5px" },
                backgroundColor: "#DBFF75",
                borderRadius: "50%",
              }}
              onClick={handleNextClick}
            >
              <KeyboardArrowRightIcon fontSize="large" />
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Achievements;
