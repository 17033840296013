import { Grid, Typography } from "@mui/material";
import React from "react";

const CommonBg = ({
  isActive,
  image,
  text,
  boxHead,
  boxText,
  Head2,
  Text2,
  stylePos,
}) => {
  return (
    <Grid
      container
      sx={{
        backgroundImage: {
          md: isActive
            ? "linear-gradient(to right, #c3e68a, #f3fadd, #abd6b2)"
            : "none",
        },
        padding: { md: "10px" },
        borderRadius: "25px",
        mt: 2,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Grid
        item
        sx={{
          backgroundImage: `url(${image})`,
          backgroundSize: "cover",
          backgroundPosition: stylePos ? stylePos : "top",
          height: "250px",
          width: "95%",
          display: "flex",
          // alignItems: "",
          flexDirection: "column",
          justifyContent: "flex-end",
          borderRadius: "20px",
          padding: "30px",
        }}
      >
        <Typography
          sx={{
            mb: 1,
            fontSize: { xs: "40px", md: "50px" },
            color: "white",
            lineHeight: { xs: 1 },
            fontWeight: 700,
          }}
        >
          {text}
        </Typography>
      </Grid>
      <Grid
        item
        sx={{
          display: isActive ? "flex" : "none",
          flexDirection: "column",
          backgroundColor: { md: "#FAFFF0" },
          width: { xs: "100%", md: "90%" },
          padding: { md: "25px" },
          borderRadius: "20px",
          justifyContent: "center",
          alignItems: "center",
          marginTop: { xs: "10px", md: "-30px" },
        }}
      >
        <Typography
          sx={{
            fontSize: { xs: "32px", md: "28px" },
            fontWeight: { xs: 400, md: 700 },
          }}
        >
          {boxHead}
        </Typography>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: 400,
            textAlign: "center",
            display: { xs: "none", md: "initial" },
          }}
        >
          {boxText}
        </Typography>
      </Grid>
      <Grid
        item
        sx={{
          display: isActive ? "flex" : "none",
          flexDirection: "column",
          width: { xs: "100%", md: "65%" },
          padding: { md: "25px" },
          justifyContent: "center",
          alignItems: { md: "center" },
          marginTop: { xs: 1, md: 2 },
          marginBottom: 3,
        }}
      >
        <Typography
          sx={{
            fontSize: { xs: "27px", md: "28px" },
            fontWeight: 600,
            whiteSpace: "nowrap",
          }}
        >
          {Head2}
        </Typography>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: 400,
            textAlign: { md: "center" },
          }}
        >
          {Text2}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default CommonBg;
