import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import Heading from "../../../../components/Headings";
import CommonButton from "../../../../components/CommonButton";
import Responsive from "./components/Responsive";
import { useNavigate } from "react-router-dom";

const BestSellers = () => {
  const navigate = useNavigate();
  const isSmall = useMediaQuery("(max-width:800px)");
  const content = [
    {
      img: "https://res.cloudinary.com/dm9ak3vvw/image/upload/v1730097932/BS3_xmmgap.svg",
      title: "Fiber Cables",
      desc: "Our Video Surveillance solutions feature high-quality Cables.",
      value: 0,
    },
    {
      img: "https://res.cloudinary.com/dm9ak3vvw/image/upload/v1730097951/BS2_ultffo.svg",
      title: "Networking Enclosure Rack",
      desc: "Our Video Surveillance solutions feature.",
      value: 2,
    },
    {
      img: "https://res.cloudinary.com/dm9ak3vvw/image/upload/v1730097944/BS1_kgjawm.svg",
      title: "Internet solution",
      desc: "Our Video Surveillance solutions feature high-quality internet solution.",
      value: 1,
    },
  ];

  const hanldeNavigation = () => {
    navigate("/products");
  };

  return (
    <Grid
      container
      sx={{
        mt: { md: "60px", lg: "40px" },
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Grid item>
        <Heading
          head={"Best Sellers"}
          text={
            "We offer a wide selection of enterprise networking equipment from leading brands like Commscope, Netrack, D-Link, TP-Link, Digisol and more."
          }
        />
      </Grid>
      <Grid item>
        <Box
          sx={{
            mt: 3,
            borderRadius: "50px",
            boxShadow: "0px 0px 15px #daf784",
          }}
        >
          <CommonButton
            btnClick={hanldeNavigation}
            text={"View all productss"}
          />
        </Box>
      </Grid>
      {isSmall ? (
        <Responsive content={content} />
      ) : (
        <Grid container spacing={3} mt={2} justifyContent="center">
          {content.map((item, index) => (
            <Grid
              item
              key={index}
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: "300px",
                  height: "350px",
                  backgroundColor: "#EDFFB8",
                  padding: "15px",
                  borderRadius: "20px",
                  boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.1)",
                }}
              >
                <img
                  src={item.img}
                  alt={item.title}
                  style={{ width: "100%", height: "65%" }}
                />
                <Box m={2}>
                  <Typography sx={{ fontSize: "27px", fontWeight: 600 }}>
                    {item.title}
                  </Typography>
                  {/* <Typography sx={{ fontSize: "14px", fontWeight: 400 }}>
                      {item.desc}
                    </Typography> */}
                </Box>
              </Box>
              <Box
                sx={{
                  width: "160px",
                  borderRadius: "50px",
                  boxShadow: "0px 0px 15px #daf784",
                  marginTop: "-15px",
                }}
              >
                <CommonButton
                  btnClick={() => {
                    navigate("products", { state: { tabValue: item.value } });
                  }}
                  text={"See More"}
                />
              </Box>
            </Grid>
          ))}
        </Grid>
      )}
    </Grid>
  );
};

export default BestSellers;
