import { Container, Grid } from "@mui/material";
import React from "react";
import ItSolutions from "./components/ItSolutions";
import CommonBg from "../../components/CommonBg";
import OurStrengths from "./components/OurStrength";
import WhyChoose from "./components/WhyChoose";

const Solutions = () => {
  return (
    <Grid sx={{ padding: "10px" }}>
      <CommonBg isActive={false} text={"Solutions"} image="https://res.cloudinary.com/dm9ak3vvw/image/upload/v1728505870/SolutionBanner_pikmkg.png" />
      <Container sx={{ mt: 4 }}>
        <ItSolutions />
        <WhyChoose />
        <OurStrengths />
      </Container>
    </Grid>
  );
};

export default Solutions;
