import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import CommonButton from "../../../../components/CommonButton";
import { useNavigate } from "react-router-dom";

const content = [
  {
    title: "Creating Values in Networking Solutions",
    image: "https://res.cloudinary.com/dm9ak3vvw/image/upload/v1726919307/Mask_group_1_ivjstf.svg",
  },
  {
    title: "Creating Values in Security",
    image: "https://res.cloudinary.com/dm9ak3vvw/image/upload/v1726919312/Group_88_ujdac2.svg",
  },
  {
    title: "Creating Values in Surveillance",
    image: "https://res.cloudinary.com/dm9ak3vvw/image/upload/v1726919310/Mask_group_dpro23.svg",
  },
  {
    title: "Creating Values in Networking Rack",
    image: "https://res.cloudinary.com/dm9ak3vvw/image/upload/v1726919314/img4_e5abao.svg",
  },
  {
    title: "Creating Values in Enclosure Racks",
    image: "https://res.cloudinary.com/dm9ak3vvw/image/upload/v1726919307/img5_j429v8.svg",
  },
  
];

const HomeBanner = React.memo(() => {
  const [currentContent, setCurrentContent] = useState(content[0]);
  const navigate = useNavigate()
  const isSmallScreen = useMediaQuery("(max-width: 700px)");
  const isSmScreen = useMediaQuery("(max-width: 900px)");

  const updateIndex = useCallback(() => {
    setCurrentContent((prevContent) => {
      const nextIndex = (content.indexOf(prevContent) + 1) % content.length;
      return content[nextIndex];
    });
  }, []);

  useEffect(() => {
    const imageInterval = 3000;
    const interval = setInterval(updateIndex, imageInterval);
    return () => clearInterval(interval);
  }, [updateIndex]);

  const handleNavigate = () => {
    navigate("/contact-us")
  }

  const fetchImages = async () => {
  const cloudName = 'dm9ak3vvw';
  const folderName = 'homePageAboutUs';
  const your_api_key = "291426298481545";
  const your_api_secret = "fn-JC0xmDfzeSuMsuoucHhEHTLU";
  
  const url = `https://api.cloudinary.com/v1_1/${cloudName}/resources/image/upload?prefix=${folderName}`;

  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': 'Basic ' + btoa(`${cloudName}:${your_api_key}:${your_api_secret}`)
      }
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    console.log(data.resources); // Process data as needed
  } catch (error) {
    console.error('Error fetching images:', error);
  }
};

useEffect(()=>{
  fetchImages()
},[]);

  return (
    <Grid
      container
      sx={{
        width: "100%",
        height: { xs: "100vh", md: "80vh" },
        mt: 3,
        position: "relative",
        overflow: "hidden",
      }}
    >
      <img
        src={"https://res.cloudinary.com/dm9ak3vvw/image/upload/v1730097330/Mask_group_hu90za.png"}
        alt="bg"
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          borderRadius: "25px",
        }}
      />
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          position: "absolute",
          height: { xs: "50%", md: "100%" },
          top: 0,
          left: 0,
          bottom: { md: 0 },
          display: "flex",
          marginLeft: { md: "50px" },
          padding: { xs: "20px", md: "40px" },
          flexDirection: "column",
          justifyContent: "space-evenly",
        }}
      >
        <Box>
          <Typography
            variant="h4"
            sx={{
              color: "#000",
              textAlign: { xs: "center", md: "left" },
              fontSize: { xs: "26px", md: "50px" },
              transition: "opacity 0.5s ease-in-out",
            }}
          >
            {currentContent.title}
          </Typography>
          <Typography
            sx={{
              textAlign: { xs: "center", md: "left" },
              fontSize: { xs: "13px", md: "15px" },
              mt: 1,
            }}
          >
            We provide networking solutions, not only to optimize business
            processes but also enhance scalability, reliability, and
            availability.
          </Typography>
          <Box
            sx={{
              mt: { xs: 1, md: 3 },
              display: "flex",
              justifyContent: { xs: "center", md: "flex-start" },
            }}
          >
            <CommonButton btnClick={handleNavigate} text="Reach Us Now" />
          </Box>
        </Box>
        <Box sx={{ display: { xs: "none", md: "flex" } }}>
          {content.map((item, index) => (
            <Box
              key={index}
              sx={{
                backgroundColor: currentContent === item ? "#A5BF13" : "#CBDE62",
                borderRadius: "50%",
                width: currentContent === item ? "16px" : "12px",
                height: currentContent === item ? "16px" : "12px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                margin: "0 3px",
                transition: "width 0.3s ease, height 0.3s ease",
              }}
            />
          ))}
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          position: "absolute",
          top: { md: 0 },
          right: 0,
          bottom: 10,
          width: "100%",
          height: { xs: "50%", md: "100%" },
          display: "flex",
          padding: "10px",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={currentContent.image}
          alt={`img${content.indexOf(currentContent) + 1}`}
          style={{
            width: "95%",
            height: "100%",
            objectFit: "cover",
            borderRadius: "15px",
            transition: "opacity 0.5s ease-in-out",
          }}
        />
        <img
          src={"https://res.cloudinary.com/dm9ak3vvw/image/upload/v1730100364/Group_67_pacmnl.png"}
          alt="sideimg"
          style={{
            width: isSmallScreen ? "42%" : isSmScreen ? "35%" : "50%",
            position: "absolute",
            bottom: 20,
            right: 30,
            zIndex: 99999,
          }}
        />
      </Grid>
    </Grid>
  );
});

export default HomeBanner;