import { Box } from "@mui/material";
import React from "react";

const CommonButton = ({ btnClick , text }) => {
  const handleClick = () => {
    if(btnClick) btnClick()
  }
  return (
    <Box sx={{ display: "flex", cursor:"pointer" }} onClick={() => handleClick()}>
      <Box
        sx={{
          backgroundColor: "#fff",
          borderRadius: "60px",
          padding: "10px 30px",
          fontSize: "16px",
          whiteSpace: "nowrap",
          display: "flex",
          fontWeight: 500,
          alignItems: "center",
          justifyContent: "center",
          width: "95px",
        }}
      >
        {text}
      </Box>
      <Box
        sx={{
          backgroundColor: "#f0f9d2",
          borderRadius: "50%",
          width: "30px",
          height: "30px",
          marginLeft: "-20px",
          padding: "5px",
        }}
      >
        <Box
          sx={{
            backgroundColor: "#fff",
            borderRadius: "50%",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <img src={"https://res.cloudinary.com/dm9ak3vvw/image/upload/v1730109765/link_yixiit.svg"} alt="link" style={{ width: "12px" }} />
        </Box>
      </Box>
    </Box>
  );
};

export default CommonButton;
