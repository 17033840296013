import {
  Autocomplete,
  Box,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import CommonButton from "../../components/CommonButton";
import emailjs from "emailjs-com";

const ContactUs = () => {
  const contactbg = "https://res.cloudinary.com/dm9ak3vvw/image/upload/v1730109380/contactbg_yvzsah.svg"
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [city, setCity] = useState("");
  const formRef = useRef();
  const isSmall = useMediaQuery("(max-width: 600px)");
  const StyleFeild = {
    border: "1px solid #dfe8b0",
    backgroundColor: "#fff",
    borderRadius: "10px",
    padding: "10px 20px",
    width: isSmall ? "80%" : "40%",
  };
  const BoxStyle = {
    width: { xs: "100%", md: "40%" },
    display: "flex",
    justifyContent: "space-between",
  };

  const YOUR_SERVICE_ID = "service_p0p6sjd";
  const YOUR_TEMPLATE_ID = "template_d9ek8wp";
  const YOUR_USER_ID = "au_gAxP4bdAFdyN6s";
  const handleSubmit = () => {
    emailjs
      .sendForm(
        YOUR_SERVICE_ID,
        YOUR_TEMPLATE_ID,
        formRef.current,
        YOUR_USER_ID
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    <Grid
      container
      sx={{
        width: "100%",
        mt: 3,
      }}
    >
      <Grid
        item
        sx={{
          width: "100%",
          height: { xs: "auto", md: "70vh" },
          display: { md: "flex" },
          padding: { xs: "10px", md: "30px" },
        }}
      >
        <Box
          sx={{
            width: { xs: "100%", md: "45%" },
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: { xs: "40vh", md: "100%" },
          }}
        >
          <img
            src={"https://res.cloudinary.com/dm9ak3vvw/image/upload/v1730109435/contactUs_gbxs4p.png"}
            alt="img"
            style={{ width: "100%", height: "100%" }}
          />
        </Box>
        <Box
          sx={{
            display: { xs: "flex", md: "none" },
            flexDirection: "column",
            alignItems: "center",
            height: { md: "60vh" },
            width: "100%",
          }}
        >
          <Box
            component="form"
            sx={{
              mt: 3,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: 1,
              width: "100%",
            }}
          >
            <input
              value="name"
              onChange={(e) => {
                setName(e.target.value);
              }}
              placeholder="Name"
              style={{ ...StyleFeild }}
            />
            <input
              value="email"
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
              style={{ ...StyleFeild }}
            />

            <input
              value="companyName"
              onChange={(e) => setCompanyName(e.target.value)}
              placeholder="Company Name"
              style={{ ...StyleFeild }}
            />
            <input
              value="city"
              onChange={(e) => setCity(e.target.value)}
              placeholder="City"
              style={{ ...StyleFeild }}
            />

            <input placeholder="Mobile No." style={{ ...StyleFeild }} />
            <Autocomplete
              options={["Passive", "Active"]}
              disableClearable
              sx={{
                width: "86%",
                border: "1px solid #dfe8b0",
                backgroundColor: "#fff",
                borderRadius: "10px",
                padding: "5px 10px",
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Enquiry Type"
                  variant="standard"
                  InputProps={{
                    ...params.InputProps,
                    disableUnderline: true,
                  }}
                />
              )}
            />

            <input
              placeholder="Type your message here..."
              style={{
                border: "1px solid #dfe8b0",
                backgroundColor: "#fff",
                borderRadius: "10px",
                padding: "10px 20px",
                width: "80%",
                height: "60px",
              }}
            />
          </Box>
          <Box
            sx={{
              boxShadow: "0px 0px 15px #daf784",
              width: { xs: "175px", md: "13%" },
              borderRadius: "20px",
              mt: 2,
            }}
          >
            <CommonButton text={"Submit"} />
          </Box>
        </Box>
        <Box
          sx={{
            display: "initial",
            width: { xs: "100%", md: "55%" },
            height: { md: "100%" },
            padding: { xs: "10px", md: "30px" },
          }}
        >
          <Typography sx={{ fontSize: "40px", fontWeight: 700 }}>
            Contact Us
          </Typography>

          <Typography sx={{ fontSize: "16px", fontWeight: 400 }}>
            Have a question ? Feel free to reach out.
          </Typography>

          <Box
            sx={{
              border: { md: "1px dashed #dfe0da" },
              padding: { md: "20px" },
              display: { md: "flex" },
              alignItems: "center",
              // justifyContent: "space-between",
              gap: { md: "25px" },
              mt: 3,
              borderRadius: "50px",
            }}
          >
            <Box
              sx={{
                border: "1px solid #dfe0da",
                padding: "10px",
                display: "flex",
                alignItems: "center",
                gap: "5px",
                borderRadius: "50px",
              }}
            >
              <Box
                sx={{
                  padding: "5px",
                  borderRadius: "30px",
                  backgroundColor: "#F1FDCD",
                }}
              >
                <MailOutlineIcon />
              </Box>
              <a href="mailto:admin@datasystems.co.in" style={{ color: "#000", fontSize: "14px", textDecorationLine:"none" }}>
                admin@datasystems.co.in
              </a>
            </Box>
            <Box
              sx={{
                display: { xs: "flex" },
                justifyContent: { xs: "space-between" },
                mt: { xs: 1, md: 0 },
                gap: { md: "25px" },
              }}
            >
              <Box
                sx={{
                  border: "1px solid #dfe0da",
                  padding: "10px",
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  borderRadius: "50px",
                  width: { xs: "44%", md: "auto" },
                }}
              >
                <Box
                  sx={{
                    padding: "5px",
                    borderRadius: "30px",
                    backgroundColor: "#F1FDCD",
                  }}
                >
                  <LocalPhoneOutlinedIcon />
                </Box>
                <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
                <a style={{textDecoration:"none", textDecorationLine:"none", color: "#000"}} href="tel:04027811708"> 040-2781 1708</a>
             
                </Typography>
              </Box>
              <Box
                sx={{
                  border: "1px solid #dfe0da",
                  padding: "10px",
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  width: { xs: "44%", md: "auto" },
                  borderRadius: "50px",
                }}
              >
                <Box
                  sx={{
                    padding: "5px",
                    borderRadius: "30px",
                    backgroundColor: "#F1FDCD",
                  }}
                >
                  <LinkedInIcon />
                </Box>
                <Link
                 onClick={() => {
                  window.open("https://www.linkedin.com/in/data-systems-609253319?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app", "_blank")
                 }}
                  style={{
                    color: "#000",
                    fontSize: "14px",
                    fontWeight: 500,
                    textDecoration: "none",
                  }}
                >
                  Data Systems
                </Link>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              width: "100%",
              mt: 2,
            }}
          >
            <li style={{ fontSize: "14px" }}>
              <span style={{ fontWeight: 600 }}>Mon - Sat</span> 10am - 6pm IST
            </li>
            <li style={{ fontSize: "14px", marginLeft: "20px" }}>
              <span style={{ fontWeight: 600 }}>Sun </span> closed
            </li>
          </Box>
        </Box>
      </Grid>
      <Grid
        item
        sx={{
          display: { xs: "none", md: "flex" },
          flexDirection: "column",
          alignItems: "center",
          backgroundImage: `url(${contactbg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "60vh",
          width: "100%",
        }}
      >
        <Box sx={{ width: "100%", textAlign: "center" }}>
          <Typography sx={{ fontSize: "28px", fontWeight: "600" }}>
            Reach out for any inquiries!
          </Typography>
        </Box>
        <Box
          component="form"
          ref={formRef}
          onSubmit={handleSubmit}
          sx={{
            mt: 3,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: 1,
            width: "100%",
          }}
        >
          <Box sx={{ ...BoxStyle }}>
            <input
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              name="name"
              placeholder="Name"
              style={{ ...StyleFeild }}
            />
            <input name="email" placeholder="Email" style={{ ...StyleFeild }} />
          </Box>
          <Box sx={{ ...BoxStyle }}>
            <input
              name="companyName"
              placeholder="Company Name"
              style={{ ...StyleFeild }}
            />
            <input name="city" placeholder="City" style={{ ...StyleFeild }} />
          </Box>
          <Box sx={{ ...BoxStyle }}>
            <input
              name="contact"
              placeholder="Mobile No."
              style={{ ...StyleFeild }}
            />
            <Autocomplete
              options={["Passive", "Active"]}
              disableClearable
              sx={{
                width: "44%",
                border: "1px solid #dfe8b0",
                backgroundColor: "#fff",
                borderRadius: "10px",
                padding: "5px 10px",
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Enquiry Type"
                  variant="standard"
                  name="enquiryType"
                  InputProps={{
                    ...params.InputProps,
                    disableUnderline: true,
                  }}
                />
              )}
            />
          </Box>
          <input
            placeholder="Type your message here..."
            name="message"
            style={{
              border: "1px solid #dfe8b0",
              backgroundColor: "#fff",
              borderRadius: "10px",
              padding: "10px 20px",
              width: "37%",
              height: "60px",
            }}
          />
        </Box>
        <Box
          sx={{
            boxShadow: "0px 0px 15px #daf784",
            width: "13%",
            borderRadius: "20px",
            mt: 2,
          }}
        >
          <CommonButton type="submit" btnClick={handleSubmit} text={"Submit"} />
        </Box>
      </Grid>
    </Grid>
  );
};

export default ContactUs;
