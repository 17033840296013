import { Box, Grid, Typography } from "@mui/material";
import React from "react";

const ProdImgBox = ({ content }) => {
  return (
    <Box width={"100%"}>
      <Grid
        container
        spacing={{ xs: 2, md: 6 }}
        justifyContent={{ xs: "center", sm: "space-between", md: "initial" }}
      >
        {content.map((item, index) => (
          <Grid
            item
            key={index}
            sx={{
              width: { xs: "80%", sm: "48%", md: "24%" },
            }}
          >
            <Box
              sx={{
                width: "100%",
                height: "280px",
                backgroundColor: "#EDFFB8",
                padding: { xs: "5px", md: "15px" },
                borderRadius: "20px",
                boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  backgroundColor: "#fff",
                  height: "70%",
                  borderRadius: "15px",
                }}
              >
                <img
                  src={item.img}
                  alt={item.title}
                  style={{ width: "100%", height: "100%" }}
                />
              </Box>

              <Box m={2}>
                <Typography sx={{ fontSize: "19px", fontWeight: 600 }}>
                  {item.title}
                </Typography>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ProdImgBox;
